import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DeleteModal from "../../../components/Common/DeleteModal";
import { Link } from "react-router-dom"
import { map } from "lodash"
import { getProjectUUIDAction, addProjectCampLead, getProjectCampLead, deleteProjectCampLead, sendProjectLeadWorksheet, getProjectSettingWorksheet, getAllProjectOpenHouse,addProjectOpenHouse,deleteProjectOpenHouse, updateProjectOpenHouse } from "../../../store/projects/actions"
import { addLeadNoteTab, getNoteLeadTab,  addFollowNoteTab, getFollowLeadTab, 
  getLeadCallLog, addLeadCallLog, addLeadEmailLog, getLeadEmailLog, getAllActLeadTab} from "../../../store/leads/actions"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import Flatpickr from "react-flatpickr"
import Moment from 'react-moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notes from "../../../assets/images/rsicons/notes.png";
import follow from "../../../assets/images/rsicons/follow-up.png";
import phonelog from "../../../assets/images/rsicons/phone-log.png";
import emaillog from "../../../assets/images/rsicons/email-log.png";

import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  Input,
  Button,

  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Tooltip
} from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SaveButton from "components/buttons/save";
class projectCurrent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lead:{},
      activeTab: "1",
      verticalActiveTab: "1",
      page: 1,
      sizePerPage: 10,
      viewmodal: false,
      modal: false,
      visible: false,
      modal_standard: false,
     
      isEditOpenHouseData:{},
      isWorkSheet: false,
      camplead: '',
      forId:'',
      selectedWorksheet: [],
      selectAll: false,
      lead:'',
      selectedValues: [],
      alllists:[],
      checkboxes: props.campleads.reduce(
        (options, item) => ({
          ...options,
          [item.id]: false,
        }),
        {}
      ),
      selectedCheckboxes: {},
      reports: [
        { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "# Prospects",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "# SRR",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "# Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "2",
        },
      ]
    }

    this.tog_standard = this.tog_standard.bind(this)
    this.onClickDeleteLead = this.onClickDeleteLead.bind(this);
   

    this.toggleEmailCanvas = this.toggleEmailCanvas.bind(this);
    this.toggleFollowCanvas = this.toggleFollowCanvas.bind(this);
    this.toggleCallLogCanvas = this.toggleCallLogCanvas.bind(this);
    this.toggleEmailLogCanvas = this.toggleEmailLogCanvas.bind(this);
    this.toggleSmsLogCanvas = this.toggleSmsLogCanvas.bind(this);
    this.toggleAddNoteCanvas = this.toggleAddNoteCanvas.bind(this);    
    
    this.toggleAddLeadCanvas = this.toggleAddLeadCanvas.bind(this);
    this.toggleOpenHouseCanvas = this.toggleOpenHouseCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.handleProjectCampLeadSubmit = this.handleProjectCampLeadSubmit.bind(this)
    this.toggleSendWorksheet = this.toggleSendWorksheet.bind(this);
    this.addOpenHouseData = this.addOpenHouseData.bind(this);
    this.updateOpenHouseData = this.updateOpenHouseData.bind(this);
    this.deleteOpenHouseData = this.deleteOpenHouseData.bind(this);
    this.tranferCanvas = this.tranferCanvas.bind(this);

    //this.handleLeadNoteSubmit = this.handleLeadNoteSubmit.bind(this)
  }
  tranferCanvas() {
    this.setState({ isTransfer: !this.state.isTransfer });
  }
  toggleAddLead(){
    this.setState(prevState => ({
      isAddLead: !prevState.isAddLead
    }));
   
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    }));
    
}
toggleFollow() {
  this.setState(prevState => ({
    isFollow: !prevState.isFollow
  }));
  
}
toggleAddNote() {
  this.setState(prevState => ({
    isAddNote: !prevState.isAddNote
  }));
  
}
toggleCallLog() {
  this.setState(prevState => ({
    isCallLog: !prevState.isCallLog
  }));
  
}
toggleWorkSheet() {
  this.setState({ isWorkSheet: !this.state.isWorkSheet });
}
toggleEmailLog() {
  this.setState(prevState => ({
    isEmailLog: !prevState.isEmailLog
  }));
  
}
toggleSmsLog() {
  this.setState(prevState => ({
    isSmsLog: !prevState.isSmsLog
  }));
  
}
toggleEmailCanvas() {
  this.setState({ lead: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleAddNoteCanvas() {
  this.setState({ lead: "", isEdit: false, isFollow: !this.state.isAddNote });
  this.toggleAddNote();
}
toggleFollowCanvas() {
  this.setState({ lead: "", isEdit: false, isFollow: !this.state.isFollow });
  this.toggleFollow();
}

toggleCallLogCanvas() {
  this.setState({ lead: "", isEdit: false, isCallLog: !this.state.isCallLog });
  this.toggleCallLog();
}

toggleEmailLogCanvas() {
  this.setState({ lead: "", isEdit: false, isEmailLog: !this.state.isEmailLog });
  this.toggleEmailLog();
}
toggleSmsLogCanvas() {
  this.setState({ lead: "", isEdit: false, isSmsLog: !this.state.isSmsLog });
  this.toggleSmsLog();
}
  toggleOpenHouseCanvas() {
    this.setState({ isEditOpenHouseData: {}, isEditOpenHouse: !this.state.isEditOpenHouse });
    this.toggleOpenHouse();
  }

  toggleOpenHouseCanvas = arg => {
    this.setState({isEditOpenHouseData: arg});
    // onGetNoteLead(camplead.leadId);
    this.toggleOpenHouse();
};

  toggleAddNoteCanvas() {
    this.setState({ camplead: "", isEdit: false, isAddNote: !this.state.isAddNote });
    this.toggleAddNote();
  }


toggleFollowCanvas = arg => {
  const lead = arg;
  const { onGetFollowLead } = this.props;
  this.setState({
    lead: {
      leadId: lead.leadId,
  },
  isEditFollow: true,
  });
  onGetFollowLead(lead.leadId);
  this.toggleFollow();
};
toggleAddNoteCanvas = arg => {
  const lead = arg;
  const { onGetNoteLead } = this.props;
  this.setState({
  lead: {
    leadId: lead.leadId,
  },
  isEdit: true,
  });
  onGetNoteLead(lead.leadId);
  this.toggleAddNote();
};

toggleCallLogCanvas = arg => {
  const lead = arg;
  const { onGetLeadCallLog } = this.props;
  this.setState({
    lead: {
      leadId: lead.leadId,
  },
 
  });
  onGetLeadCallLog(lead.leadId);
  this.toggleCallLog();
};
toggleEmailLogCanvas = arg => {
  const lead = arg;
  const { onGetLeadEmailLog } = this.props;
  this.setState({
  lead: {
    leadId: lead.leadId,
  },
  isEditCall: true,
  });
  onGetLeadEmailLog(lead.leadId);
  this.toggleEmailLog();
};
toggleSmsLogCanvas = arg => {
  const lead = arg;
  this.setState({
    lead: {
      leadId: lead.id
      },
      
    
  });
  this.toggleSmsLog();
};
  toggleAddLeadCanvas() {
    this.setState({  isAddLead: !this.state.isAddLead });
    //this.toggleAddLead();
  }



  toggleSendWorksheet() {
    this.setState({  camplead: "", isSendWorksheetEmail: false, isWorkSheet: !this.state.isWorkSheet });
    this.toggleWorkSheet();
  }
 
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  handleProjectCampLeadSubmit(value) {
    //console.log(value);
    this.props.addProjectCampLead(value, this.props.history);
    //this.toggleAddLead();
  }
  componentDidMount() {
    const { match: { params }, ongetProjectUUIDAction, onGetProjectCampLead, onGetNoteLead, onGetProjectSettingWorksheet,onGetAllProjectOpenHouse } = this.props;
    if (params && params.projectId && params.id) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectCampLead(localStorage.getItem('userId'),params.id);
      onGetProjectSettingWorksheet(params.id)
      onGetAllProjectOpenHouse(localStorage.getItem('userId'),params.id);
     
    }
    //console.log(this.props.campleads)
  }
  toggleDeleteLeadModal = () => {
    this.setState(prevState => ({
      deleteLeadModal: !prevState.deleteLeadModal,
    }));
  };
  onClickDeleteLead = (camplead) => {
    this.setState({ camplead: camplead });
    this.setState({ deleteLeadModal: true });
  };

  handleDeleteCampLeqad = () => {
    const { onDeleteCampLead } = this.props;
    const { camplead } = this.state;
    //console.log(project);
    if (camplead.id !== undefined) {
      onDeleteCampLead(camplead.id);
      this.setState({ deleteLeadModal: false });
    }
  };
 
  toggleEmailCanvas = arg => {
    const lead = arg;
    const { onGetAllActLead } = this.props;
    this.setState({
      lead: {
        leadId: lead.leadId,
      },
        isEdit: true,
    });
    onGetAllActLead(lead.leadId)
    this.toggle();
};
  
 
addOpenHouseData(values){
  console.log( values);
  
  this.props.addProjectOpenHouse(values);
}

updateOpenHouseData(values){
  console.log( values);
  
  this.props.updateProjectOpenHouse(values);
}

deleteOpenHouseData(data){
  
  this.props.deleteProjectOpenHouse(data.id);
}
handleSelectAll = () => {
  const { selectAll, checkboxes } = this.state;
  const newSelectAll = !selectAll;
  const newCheckboxes = {};
  
  map(this.props.allProjectOpenHouseData, (data) => {
    newCheckboxes[data.id] = newSelectAll;
  });

  this.setState({
    selectAll: newSelectAll,
    checkboxes: newCheckboxes
  });
};

handleCheckboxChange = (event) => {
  const { name, checked } = event.target;
  const { checkboxes } = this.state;

  const newCheckboxes = {
    ...checkboxes,
    [name]: checked
  };

  const allChecked = Object.values(newCheckboxes).every(Boolean);

  this.setState({
    checkboxes: newCheckboxes,
    selectAll: allChecked
  });
};
toggleSendWorksheet = arg => {
  const camplead = arg;
  const listItems = [];
  const {  checkboxes } = this.state;
  const allLeads = Object.keys(checkboxes)
    .filter((key) => checkboxes[key])
    .map((key) => this.props.campleads.find((option) => option.id == key));
    const leadIds = allLeads.map(lead => lead.id);
    const commaSeparatedLeadIds = leadIds.join(',');
    //console.log(commaSeparatedLeadIds);

  this.setState({
  camplead: {
      leadId: camplead.leadId,
      alllists: commaSeparatedLeadIds
  },
  isSendWorksheetEmail: true,
  });
  this.toggleWorkSheet();
};

  render() {
    const phoneNumberMask = [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const { projectData, onAddLeadNote, onAddLeadFollowUp, onAddCallLog, onAddEmailLog, onSendWorksheetEmailLog, worksheets,allProjectOpenHouseData } = this.props;
    const { leadNotes, leadFollows, calllogs, emaillogs, activities } = this.props;
    const { campleads } = this.props;
    const { deleteLeadModal,forId } = this.state;
    const { isEdit, isEditFollow, isEditCall, isEditEmail, isSendWorksheetEmail } = this.state;
    const lead = this.state.lead;
    const camplead = this.state.camplead;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { selectAll, checkboxes, selectedValues } = this.state;
    const isAnyTrue = Object.values(checkboxes);
    const trueCount = isAnyTrue.filter((value) => value === true).length;
    //console.log(localization);
    //console.log(worksheets[0].emailCampaignContent);
    const userData = JSON.parse(localStorage.getItem("authUser"));
    const agentEMail = userData.resultData.userName;
    //console.log(agentEMail);
      return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteLeadModal}
          onDeleteClick={this.handleDeleteCampLeqad}
          onCloseClick={() => this.setState({ deleteLeadModal: false })}
        />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Activity")} />
                </Col>
                <Col sm="4">
                <div className="pt-2 float-end mt-2">
                <Link to="#" onClick={this.toggleAddLeadCanvas} className="btn btn btn-light btn-md float-end"><i className="mdi mdi-plus ml-1"></i> New Lead </Link>
                </div>
                </Col>
                </Row>
         
            <Row>
                   <Col lg="12">
                   <Nav tabs className="projectTab projectNav">
                  
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/campaign/current-prospects/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("New Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/srr-filled/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Transferred leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/worksheet-status/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheet Status")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/client/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav>                    
                    </Col>  
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                 

                  <div className="mt-2 pt-2 float-end" style={{marginBottom:40}}>
                     
                      
                    
                  
                      </div>

                      <div className="table-rep-plugin">
                      <div
                        className=" mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              
                              <Th data-priority="1">{this.props.t("Date created")}</Th>
                              <Th>{this.props.t("Name")}</Th>
                              <Th data-priority="3">{this.props.t("Phone")}</Th>
                              <Th data-priority="3">{this.props.t("Email")}</Th>
                             
                            
                              <Th data-priority="3">{this.props.t("By")}</Th>
                              <Th data-priority="3">{this.props.t("Source")}</Th>
                              <Th data-priority="3">{this.props.t("Action")}</Th>

                            </Tr>
                          </Thead>
                          <Tbody className="tbody">

                          {allProjectOpenHouseData?.slice(0, 1).map((data, prokey) => ( 
                              <Tr key={prokey}>
                                  <Td> <Moment format="D MMM YY">{data.lastContact}</Moment>  </Td>
                                <Td>{data.firstName + " " + data.lastName} </Td>
                                <Td>      {data.mobile}   </Td>
                                <Td>  {data.email}  </Td>  
                               
                             
                                
                               <Td> Mohit Grover      </Td> 
                               
                                <Td>Email</Td>
                                <Td>{data.isWrokSheetFilled ? 'Submitted' : 'Pending' }</Td>
                                <Td>
                                  <UncontrolledDropdown direction="up">
                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                      Action <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    <DropdownItem onClick={this.tranferCanvas}>Transfer</DropdownItem>
                                      <DropdownItem>Edit</DropdownItem>
                                      <DropdownItem>Delete</DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>

                                </Td>

                              </Tr>
                            ))}

                          </Tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>

            </Row>
            </Col>
            </Row>
          </Container>
        </div>



        <Offcanvas
          isOpen={this.state.isRight}
          direction="end"
          toggle={this.toggleEmailCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailCanvas}>
            All Activities
          </OffcanvasHeader>
          <OffcanvasBody>
          <ul className="verti-timeline list-unstyled">
                                        {map(
                                          activities,
                                          (allactivity, allactkey) => (
                                            <li
                                              className="event-list eventlist"
                                              key={"_allact_" + allactkey}
                                            >
                                              <div className="event-timeline-dot">
                                                {(() => {
                                                  if (
                                                    allactivity.activitySubType ==
                                                      8 ||
                                                    allactivity.activitySubType ==
                                                      9 ||
                                                    allactivity.activitySubType ==
                                                      7
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={emaillog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                      3 ||
                                                    allactivity.activitySubType ==
                                                      4 ||
                                                    allactivity.activitySubType ==
                                                      5
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={phonelog} />
                                                      </>
                                                    );
                                                  } else if (
                                                    allactivity.activitySubType ==
                                                    10
                                                  ) {
                                                    return (
                                                      <>
                                                        <img src={follow} />
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <img src={notes} />
                                                      </>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                  <h5 className="font-size-14">
                                                    <Moment format="D MMM YY">
                                                      {allactivity.addedDate}
                                                    </Moment>
                                                  </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <h5 className="font-size-14">
                                                    {
                                                      localization[
                                                        "ActivitySubType_" +
                                                          allactivity.activityType +
                                                          "_" +
                                                          allactivity.activitySubType
                                                      ]
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="exNote">
                                                {allactivity.activityRemarks}
                                              </div>
                                            </li>
                                          )
                                        )}
                                      </ul>
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isEditOpenHouse}
          direction="end"
          toggle={this.toggleOpenHouseCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleOpenHouseCanvas}>
            {/* {this.state.isEditOpenHouseData.name} */}
            Add/Edit Lead Data
          </OffcanvasHeader>
          <OffcanvasBody>
          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id : this.state.isEditOpenHouseData.id ??"",
                    projectOpenHouseId: this.state.isEditOpenHouseData.projectOpenHouseId ??"",
                    agentId:this.state.isEditOpenHouseData.agentId??projectData.agentId,
                    projectId:this.state.isEditOpenHouseData.projectId ??projectData.id,
                    name: this.state.isEditOpenHouseData.name??"",
                    mobileNo: this.state.isEditOpenHouseData.mobileNo??"",
                    email: this.state.isEditOpenHouseData.email??'',
                    withAgent: this.state.isEditOpenHouseData.withAgent??'',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("This is Required"),
                    email: Yup.string().required("This is Required"),
                    
                    // mlsurl: Yup.string().required("This is Required")
                  })}

                  onSubmit={values => {
                    this.state.isEditOpenHouseData.agentId?this.updateOpenHouseData(values):this.addOpenHouseData(values);
                    // console.log(values);
                    // this.submitFormData(values);
                  }
                }

                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card>
                <CardBody>
                <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="name">
                              {this.props.t(" Name*")}
                            </Label>
                           <Field
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
         
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                           <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              id="email"
                            />
                             <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobileNo">
                              {this.props.t("Phone")}
                            </Label>
                           <Field
                              name="mobileNo"
                              type="text"
                              className={
                                "form-control" +
                                (errors.mobileNo && touched.mobileNo
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mobileNo"
                            />
                             <ErrorMessage
                                  name="mobileNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        

                      
                        {/* <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="endDate">
                              {this.props.t("End Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.endDate && touched.endDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('endDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col> */}
                        <Col sm="12">
                        <hr></hr>
                        </Col>
                        {/* <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient">
                                    {this.props.t("Are you currently working with an Agent? ")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline1"
                                      name="withAgent"
                                      className="form-check-input"
                                      value="Yes"
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline1">
                                      Yes
                                    </label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline2"
                                      name="withAgent"
                                      className="form-check-input"
                                      value="No"
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline2">
                                      No
                                    </label>
                                  </div>
                            
                                </FormGroup>
                              </Col> */}
                   
            
                        <Col sm="12">
                        <button type="submit" className="btn btn-primary w-md float-end mt-27">Submit</button>  
                        </Col>
                            
                        </Row>

                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isAddNote}
          direction="end"
          toggle={this.toggleAddNoteCanvas}
        >
          <OffcanvasHeader toggle={this.toggleAddNoteCanvas}>
            Add Notes
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: lead.leadId,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                   
                  onAddLeadNote(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleAddNote();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Add Note")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isCallLog}
          direction="end"
          toggle={this.toggleCallLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleCallLogCanvas}>
            Call Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: lead.leadId


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddCallLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleCallLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />  

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="callNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>               
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isFollow}
          direction="end"
          toggle={this.toggleFollowCanvas}

        >
          <OffcanvasHeader toggle={this.toggleFollowCanvas}>
            Follow Us
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                refId: lead.leadId,
                addNote: (this.state && this.state.addNote) || "",
                nextFollowupDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEditFollow) {
                   //console.log(values)
                  onAddLeadFollowUp(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleFollow();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">

                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb15">

                        <Label htmlFor="logDate">
                          Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.nextFollowupDate && touched.nextFollowupDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.nextFollowupDate}
                          onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (follLog, follLogkey) => (

                    <li className="event-list eventlist" key={"_note_" + follLogkey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{follLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{follLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                          
            
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isEmailLog}
          direction="end"
          toggle={this.toggleEmailLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailLogCanvas}>
            Email Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType:'',
                addedDate: new Date(),
                refId: lead.leadId  

              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddEmailLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleEmailLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent" onChange={handleChange} />
                          Email Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Received" onChange={handleChange} />
                          Email Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced" onChange={handleChange} />
                          Email Bounced
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="remarks">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="remarks"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="remarks"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emaillogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isSmsLog}
          direction="end"
          toggle={this.toggleSmsLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleSmsLogCanvas}>
            SMS Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                logDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={this.handleLeadEmailSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="SMS Sent" onChange={handleChange} />
                          SMS Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Received" onChange={handleChange} />
                          SMS Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Bounced" onChange={handleChange} />
                          SMS Bounced
                        </label>

                      </div>

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="logDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.logDate && touched.logDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.logDate}
                          onChange={(value) => setFieldValue('logDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="smsNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="smsNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="smsNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isAddLead}
          direction="end"
          toggle={this.toggleAddLeadCanvas}

        >
          <OffcanvasHeader toggle={this.toggleAddLeadCanvas}>
            Add New Prospect
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                projectCampaignId: this.props.match.params.id,
                projectCampaignGuid: this.props.match.params.projectId,
                firstName: (this.state && this.state.firstName) || "",
                lastName: (this.state && this.state.lastName) || "",
                phone: (this.state && this.state.phone) || "",
                mobile: (this.state && this.state.mobile) || "",
                email: (this.state && this.state.email) || "",



              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("This is required"),
                lastName: Yup.string().required("This is required"),
                phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                mobile: Yup.string().required('This is required'),
                email: Yup.string().email('Enter Valid Email').required('This is required')

              })}

              onSubmit={this.handleProjectCampLeadSubmit}
            >
              {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="firstName">
                          {this.props.t("First Name")}
                        </Label>
                        <Field
                          name="firstName"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.firstName && touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="lastName">
                          {this.props.t("Last Name")}
                        </Label>
                        <Field
                          name="lastName"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.lastName && touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="phone">
                          {this.props.t("Phone")}
                        </Label>
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder=""
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          )}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="mobile">
                          {this.props.t("Mobile")}
                        </Label>
                        <Field
                          name="mobile"
                          className={
                            "form-control" +
                            (errors.mobile && touched.mobile
                              ? " is-invalid"
                              : "")
                          }
                          id="mobile"
                          placeholder=""
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}

                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="email">
                          {this.props.t("Email")}
                        </Label>
                        <Field
                          name="email"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.email && touched.email
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="leadSourceId">
                          {this.props.t("Source")}
                        </Label>
                        <Field
                          name="leadSourceId"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.leadSourceId && touched.leadSourceId
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="leadSourceId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="referredBy">
                          {this.props.t("Referred By")}
                        </Label>
                        <Field
                          name="referredBy"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.referredBy && touched.referredBy
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="referredBy"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>

                  </Row>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>


        <Offcanvas
          isOpen={this.state.isWorkSheet}
          direction="end"
          toggle={this.toggleSendWorksheet}

        >
          <OffcanvasHeader toggle={this.toggleSendWorksheet}>
            Send Worksheet
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
             initialValues={{
                //subject:'',
                projectCampaignLeadId: camplead.alllists,
                campaignProjectId: parseInt(this.props.match.params.id),
                agentId:parseInt(localStorage.getItem("userId")),
                worksheetSubject:'Worksheet submission for ' + projectData.projectName,
                worksheetNotes:'<p>As discussed, find the link  to submit the worksheet for the ' + projectData.projectName + '<br /> Do fill in all the form and attach verfication documents\
                <br /> Thanks <br /> '+agentEMail+'</p>'
               

              }}
              validationSchema={Yup.object().shape({
                worksheetSubject: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {
                console.log(values);
                if (isSendWorksheetEmail) {
                   //console.log(values);
                 onSendWorksheetEmailLog(values)
                } else {

                }
                this.toggleWorkSheet();
            }}
            >
              {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <h4><small>{trueCount} Record Selected</small></h4>
                    </Col>
                    <Col md="12">
                      <h4><small>Worksheet link worksheets.realtyspacehub.com/agent/clientName</small></h4>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="worksheetSubject">
                          {this.props.t("Subject")}
                        </Label>
                        <Field
                          name="worksheetSubject"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.worksheetSubject && touched.worksheetSubject
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="worksheetSubject"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>





                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="worksheetNotes">
                          {this.props.t("Email Content")}
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data ={values.worksheetNotes}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue('worksheetNotes', data);
                          }}
                        />
                        <ErrorMessage
                          name="worksheetNotes"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>

                  </Row>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
        <Offcanvas
              isOpen={this.state.isTransfer}
              direction="end"
              toggle={this.tranferCanvas}
            >
              <OffcanvasHeader toggle={this.tranferCanvas}>
                  Transfer Lead
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:  '',
                  
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    agentId: Yup.string().required("This Field in required"),
                  })}

                  onSubmit={this.handleTransferSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                        <Row className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Created by  :</strong> <span className="">Rohit Grover</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Source  :</strong> <span className="">Website</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Contact info  :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Name  :</strong> <span className="">Hoe Alva</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Address  :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Mobile  :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Secondary phone  :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Email :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-1">
                        <Col sm={12}>
                        <h6><strong>Notes :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                      <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Transfer to agent")}
                            </Label>
                            <Field
                                        name="eventTitle"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                      
                       

                      </Row>
                      <div><SaveButton>Save</SaveButton></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
      </React.Fragment>
    )
  }
}

projectCurrent.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  className: PropTypes.any,
  addProjectCampLead: PropTypes.func,
  loading: PropTypes.object,
  onGetProjectCampLead: PropTypes.func,
  campleads: PropTypes.array,
  onDeleteCampLead: PropTypes.func,
  onAddLeadNote: PropTypes.func,
  onGetNoteLead:PropTypes.func,
  leadNotes:PropTypes.array,
  onGetFollowLead:PropTypes.func,
  leadFollows:PropTypes.array,
  onAddLeadFollowUp:PropTypes.func,
  onGetLeadEmailLog:PropTypes.func,
  onGetAllProjectOpenHouse:PropTypes.func,
  onGetLeadCallLog:PropTypes.func,
  calllogs:PropTypes.array,
  emaillogs:PropTypes.array,
  onAddCallLog:PropTypes.func,
  onAddEmailLog:PropTypes.func,
  onSendWorksheetEmailLog:PropTypes.func,
  onGetProjectSettingWorksheet: PropTypes.func,
  worksheets: PropTypes.array,
  allProjectOpenHouseData: PropTypes.array,
  addProjectOpenHouse: PropTypes.func,
  updateProjectOpenHouse: PropTypes.func,
  deleteProjectOpenHouse: PropTypes.func,
  onGetAllActLead:PropTypes.func,
  activities:PropTypes.array
}
const mapStateToProps = ({ Project,leads }) => (
 
  {
    projectData: Project.projectData,
    loading: Project.loading,
    campleads: Project.campleads,
    leadNotes: leads.leadNotes,
    leadFollows: leads.leadFollows,
    calllogs:leads.calllogs,
    emaillogs:leads.emaillogs,
    worksheets:Project.worksheets,
    allProjectOpenHouseData : Project.allProjectOpenHouseData,
    activities:leads.activities
  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectCampLead: (data) => dispatch(addProjectCampLead(data)),
  onGetProjectCampLead: (agentId,projectId) => dispatch(getProjectCampLead(agentId,projectId)),
  onDeleteCampLead: (id) => dispatch(deleteProjectCampLead(id)),
  onAddLeadNote: (data) => dispatch(addLeadNoteTab(data)),
  onGetNoteLead: (leadId) => dispatch(getNoteLeadTab(leadId)),
  onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
  onAddLeadFollowUp:(data) => dispatch(addFollowNoteTab(data)),
  onAddCallLog:(data) => dispatch(addLeadCallLog(data)),
  onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
  onAddEmailLog:(data) => dispatch(addLeadEmailLog(data)),
  onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
  onSendWorksheetEmailLog:(data) => dispatch(sendProjectLeadWorksheet(data)),
  onGetProjectSettingWorksheet: (projectId) => dispatch(getProjectSettingWorksheet(projectId)),
  onGetAllProjectOpenHouse: (agentId, projectId) => dispatch(getAllProjectOpenHouse(agentId, projectId)),
  addProjectOpenHouse: (data) => dispatch(addProjectOpenHouse(data)),
  updateProjectOpenHouse: (data) => dispatch(updateProjectOpenHouse(data)),
  deleteProjectOpenHouse: (id) => dispatch(deleteProjectOpenHouse(id)),
  onGetAllActLead:(leadId) => dispatch(getAllActLeadTab(leadId))

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectCurrent)))

import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import PermissionAlert from "../../components/Common/permissionalert";
import RsCard from "components/Rscards/Rscard";
import RSPieChart from "components/charts/RSPieChart";
import RSBarChart from "components/charts/RSBarChart";
import RsAdNew from "components/buttons/Adnew";
import CreateLead from './lead-create'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, Label
} from "reactstrap"
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Won vs lost %", iconClass: "bx bx-copy-alt", description: "5" },
       
        {
          title: "Hot leads",
          iconClass: "bx bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Total Leads",
          iconClass: "bx bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Pipeline value",
          iconClass: "bx bx-plus-medical",
          description: "20",
        },
      ]
    }

    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.tranferCanvas = this.tranferCanvas.bind(this);
  }
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });
    
  }
  tranferCanvas() {
    this.setState({ isTransfer: !this.state.isTransfer });
  }
  render() {
    const userModule = localStorage.getItem('userModule');
    const leadSourceData = {
      series: [35, 25, 14, 10],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["Google", "Website", "Facebook", "Instagram"],
        colors: ["#6CE5E8", "#41B8D5", "#2D8BBA", "#2F5F98"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
   
   
    return (
      <React.Fragment>
          
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Dashboard")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
                  <div className="">
                 
                  <RsAdNew to="/lead/import-lead" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Lead</RsAdNew>
                  <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
                  </div>
                 
                </div>
            </Col>
            </Row>
            <Row className="mt-20 mb-20"> 
            
              <Col xl="12">
              
                {/* <Row className="mb20" style={{marginTop:20}}> 
            {this.state.reports.map((report, key) => (
            <Col md="3" className="" key={key}>
                <RsCard title={report.title} description={report.description} iconClass={report.iconClass} />
              </Col>
            ))}
            </Row> */}
           

            <Row>
            <Col lg={6}>
               <RSPieChart title="Lead Source" filter={false} height="310" series={leadSourceData.series} options={leadSourceData.options} />
            </Col>
            <Col lg="6">
            <div className="card">
            <div className="card-body">
                <div className="card-title mb-4 float-start">Top 5 lead converting agents</div>
                <table
                      id=""
                      className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Branch</th>
                            <th>#Office leads</th>
                            <th>#Clients</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mohit Grover</td>
                            <td>1234567890</td>
                            <td>mohit@esimplified.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                          
                          </tr>
                          <tr>
                            <td>Sudhir Singh</td>
                            <td>1234567890</td>
                            <td>sudhir@esimplified.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                           
                          </tr>
                          <tr>
                            <td>Rohit Grover</td>
                            <td>1234567890</td>
                            <td>rohit@esimplified.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                           
                          </tr>
                          <tr>
                            <td>Vineet Kumar</td>
                            <td>1234567890</td>
                            <td>vineet@example.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                           
                          </tr>
                          <tr>
                            <td>John Deo</td>
                            <td>1234567890</td>
                            <td>jhon@deo.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                           
                          </tr>
                        </tbody>
                </table>
                </div>
            </div>
            </Col>
            
            </Row>
           
            </Col>
            </Row>
            <Row>
            <Col lg="12">
            <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start">Leads</CardTitle>
         
          <div className="">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Created</th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Phone</th>
                  
                  <th className="align-middle">Source</th>
                  <th className="align-middle">Contact status</th>
                  <th className="align-middle">Transfer Status</th>
                 
                  <th className="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Oct 3, 2024</td>
                  <td>Hoe Alva</td>
                  <td>647-789-2356</td>
                  <td>Google</td>
                  <td></td>
                  <td>Not assigned</td>
                  <td><Link className="btn btn btn-action btn-sm" to="/lead/lead-detail/120/1d690c65-5fa9-4e4c-8144-06c78c9b2e26" onClick={this.tranferCanvas}><i className="font-size-16 align-middle btn-i  bx  bx bx-timer" style={{marginRight:10}}></i>Activity</Link>{" "}<Link className="btn btn btn-primary btn-sm" to="#" onClick={this.tranferCanvas}><i className="font-size-16 align-middle btn-i  bx bx-link-external" style={{marginRight:10}}></i>Transfer</Link></td>
                </tr>

                <tr>
                  <td>Oct 1, 2024</td>
                  <td>Mike doug</td>
                  <td>647-231-6715</td>
                  <td>Website</td>
                  <td></td>
                  <td>James Kirk</td>
                  <td><Link className="btn btn btn-action btn-sm" to="/lead/lead-detail/120/1d690c65-5fa9-4e4c-8144-06c78c9b2e26" onClick={this.tranferCanvas}><i className="font-size-16 align-middle btn-i  bx  bx bx-timer" style={{marginRight:10}}></i>Activity</Link> {" "}<Link className="btn btn btn-primary btn-sm" to="#" onClick={this.tranferCanvas}><i className="font-size-16 align-middle btn-i  bx bx-link-external" style={{marginRight:10}}></i>Transfer</Link></td>
                </tr>
              </tbody>
            </table>
          </div>

      
        </CardBody>
      </Card>
            </Col>
          </Row>
            
          </Container>
        </div>
      

<Offcanvas
                        isOpen={this.state.isAddNew}
                        direction="end"
                        toggle={this.toggleAddNew}
                        style={{ width: 800 }}
                      >
                        <OffcanvasHeader toggle={this.toggleAddNew}>
                         New Lead
                        </OffcanvasHeader>
                        <OffcanvasBody>
                          <CreateLead toggleAddNew={this.toggleAddNew} />
                        </OffcanvasBody>
                        </Offcanvas>

                        <Offcanvas
              isOpen={this.state.isTransfer}
              direction="end"
              toggle={this.tranferCanvas}
            >
              <OffcanvasHeader toggle={this.tranferCanvas}>
                  Transfer Lead
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:  '',
                  
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    agentId: Yup.string().required("This Field in required"),
                  })}

                  onSubmit={this.handleTransferSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                        <Row className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Created by  :</strong> <span className="">Rohit Grover</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Source  :</strong> <span className="">Website</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Contact info  :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Name  :</strong> <span className="">Hoe Alva</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Address  :</strong> <span className="">1815 Ironstone Manor, Pickering, ON L1W 3W9, Canada</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Mobile  :</strong> <span className="">1234567890</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Secondary phone  :</strong> <span className="">9876543210</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Email :</strong> <span className="">mohit@esimplified.ca</span></h6>
                        </Col>
                        </Row>
                        <Row  className="mb-3">
                        <Col sm={12}>
                        <h6><strong>Notes :</strong> <span className=""></span></h6>
                        </Col>
                        </Row>
                      <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Transfer to agent")}
                            </Label>
                            <Field
                                        name="eventTitle"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                      
                       

                      </Row>
                      <div><button type="submit" className="btn btn-primary">Save</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
      </React.Fragment>
    )
  }
}
Dashboard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(Dashboard))

import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { API_URL } from "../../../helpers/app_url";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import { connect } from "react-redux";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { withTranslation } from "react-i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import SaveButton from "components/buttons/save";
class PublishPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: "",
     projectData:'',
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
      axios.get(API_URL + `ProjectLandingPage/${this.props.match.params.id}`, { headers: { Authorization: str } })
      .then(res => {
        const pageData = res.data;
        this.setState({ pageData: pageData });  
      });

      axios.get(API_URL + `ProjectExt/guid?projectid=${this.props.match.params.projectId}`, { headers: { Authorization: str } })
      .then(res => {
        const projectData = res.data;
        this.setState({
          projectData: projectData,
        });  
      });
  }
  handleSubmit = (values, { setSubmitting }) => {
    console.log(values);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    axios.put(API_URL + `ProjectLandingPage`, values, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': str
      }
    })
      .then(response => {
        console.log('Form submitted successfully:', response.data);
        this.props.history.push(`/pre-construction/campaign/landing-page/${this.state.projectData.id}/${this.state.projectData.projectId}`)
      })
      .catch(error => {
        console.error('Error submitting form:', error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

   removeFormTags = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    //doc.querySelectorAll('form').forEach(form => form.remove());
    doc.querySelectorAll('input[required], textarea[required], select[required]').forEach(el => {
      el.removeAttribute('required');
    });
    return doc.body.innerHTML;
  };
  render() {
    console.log(this.state.projectData.landingPageFullContent)
    return (
    
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <ToastContainer autoClose={2000} />
          <Container fluid>
            <div className="overlay-email">
              
                
                    {/* Render Breadcrumb */}
                    
                  
                    <Row className="mb20">
                                <Col sm="12">
                                
                                </Col>
                
                    </Row>
                    <Row>
                    <Col lg="8">
                      <div className="m-4 ms-0 card mt-80">
                      { <div dangerouslySetInnerHTML={{__html:this.state.pageData.landingPageFullContent}}></div> } 
                        </div>
                      </Col>
                    <Col lg="4">
                   
                    <Formik
                enableReinitialize={true}
                initialValues={{
                  
                    id: this.state.pageData.id,
                    projectLandingPageId: this.state.pageData.projectLandingPageId,
                    landingPageProjectId: this.state.pageData.landingPageProjectId,
                    landingPageTemplateId: this.state.pageData.landingPageTemplateId,
                    landingPagePermaLink: this.state.pageData.landingPagePermaLink,
                    landingPageContent:  this.state.pageData.landingPageContent,
                    landingPageCss: this.state.pageData.landingPageCss,
                    //landingPageFullContent: this.state.pageData.landingPageFullContent,
                    agentId: this.state.pageData.agentId,
                    isActive: this.state.pageData.isActive,
                    landingPagePublishStatus: this.state.pageData.landingPagePublishStatus,
                    landingPageUrl : `https://pages.realtyspacehub.com/${this.props.match.params.projectId}`, 
                    landingPageTitle:this.state.projectData.projectName
                }}
                validationSchema={Yup.object().shape({
                  
                })}
                onSubmit={this.handleSubmit}
               
              >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                  <Form className="needs-validation">
                     <span className="float-end mt-20" style={{marginRight:20}}>
                                        <SaveButton> Publish Page </SaveButton>
                        </span>
                            <div className="clearfix"></div>
                        <Card className="m-4 me-0">

                          <CardBody>
                            <Row className="p-4 langing-page">

                              <Col sm="12" className="border">
                              <h2>Page Url <small></small></h2>
                                <FormGroup className="mb-3">
                                  
                                  <Field
                                    name="landingPageUrl"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.landingPageUrl &&
                                      touched.landingPageUrl
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="landingPageUrl"
                                  />
                                  <ErrorMessage
                                    name="landingPageUrl"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="12" className="border">
                              <h2>Page Title</h2>
                                <FormGroup className="mb-3">
                                 
                                  <Field
                                    name="landingPageTitle"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.landingPageTitle &&
                                      touched.landingPageTitle
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="landingPageTitle"
                                  />
                                  <ErrorMessage
                                    name="landingPageTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col sm="12" className="border">
                              <h2>Any Page Description</h2>
                                <FormGroup className="mb-3">
                                 
                                <Field
                                    name="landingPageDescription"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.landingPageDescription &&
                                      touched.landingPageDescription
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="landingPageDescription"
                                  />
                                  <ErrorMessage
                                    name="landingPageDescription"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm="12" className="border">
                              <h2>CC Email for Enquiry Form</h2>
                                <FormGroup className="mb-3">
                                 
                                <Field
                                    name="landingEmailForEnquiry"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.landingEmailForEnquiry &&
                                      touched.landingEmailForEnquiry
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="landingEmailForEnquiry"
                                  />
                                  <ErrorMessage
                                    name="landingEmailForEnquiry"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col sm="6"></Col>
                            </Row>
                          </CardBody>
                        </Card>
                        </Form>
                      )}
                          </Formik>
                     
                    </Col>
                    
                      </Row>

            </div>
          </Container>

        </div>
      </React.Fragment>
    );
  }
}
PublishPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
};
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PublishPage));


import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import SaveButton from 'components/buttons/save';
import { API_URL } from "../../../helpers/app_url";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getContactDetail } from "../../../store/actions";
import ContactData from '../forms/contact-data';
import ContactDetailMenu from '../menu/detail-menu'
class ConatctInvestorQuestionAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyersQuestions:[]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
            const { match: { params }, onGetContactDetail} = this.props;
            if (params && params.id) {
              onGetContactDetail(params.id);
            }
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        const authHeader = { Authorization: `Bearer ${token}` };
          axios.get( API_URL + `QuestionAnswer/allforagenttype`, {
            params: {
              forType: 3,

            }, headers: { Authorization: str }
          })
            .then(res => {
              const buyersQuestions = res.data;
              this.setState({ buyersQuestions });
            });
      }
      handleSubmit(values) {
       
    }
    
   
  


    render() {
        const {contactDetail } = this.props;
        return (
          <React.Fragment>
          <ToastContainer autoClose={2000} />
          <div className="page-content project-page-content">
            <MetaTags>
              <title>Lead Detail | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0">
           <Row>
            <Col sm="3" className="pe-0">
                <ContactData />
            </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
                <Breadcrumbs
                  title="Contact"
                  breadcrumbItem="Contact Detail"
                />
           
            </Col>
            <Col sm='4'>
            <h2>{contactDetail.firstName + " " + contactDetail.lastName }</h2>
            </Col>
            </Row>
                 
            <ContactDetailMenu /> 
            
            <Card>
              <CardBody>
            <Formik
                enableReinitialize={true}
                initialValues={{
                  
                    questionAnswers: this.state.buyersQuestions.reduce((acc, question) => {
                      acc[question.id] = { answersOption: '' };
                      return acc;
                    }, {})
                  }}

                validationSchema={ Yup.object().shape({
                  
                })}
                onSubmit={this.handleSubmit}
            >
                {({ errors,  touched, values, handleChange, setFieldValue }) => (
                    <Form className="needs-validation">
                        <CardBody>
                       
                            <div className="row mb-3">
                            {
                                              this.state.buyersQuestions
                                                .map(buyersQuestion =>

                                                  <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                                    <FormGroup>

                                                      <Label htmlFor={"buyerQuest" + buyersQuestion.id}>
                                                        {buyersQuestion.question}
                                                      </Label>
                                                      {(() => {
                                                        if (buyersQuestion.questionType == 1) {

                                                          const asns = buyersQuestion.answersOptions;
                                                          const res = asns.split(',');
                                                          const result = res.map((item, index) => <option key={index} value={item}



                                                          >{item}</option>);
                                                          return (
                                                            <div>
                                                              <Field as="select" className="form-control" name={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                                                <option value="">Select</option>
                                                                {result}
                                                              </Field>
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 2) {

                                                          return (

                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="checkbox"
                                                                id={"buyerQuest" + buyersQuestion.id}
                                                                className="questCheckbox"
                                                                onChange={(e) => setFieldValue(
                                                                    `questionAnswers.${buyersQuestion.id}.answersOption`,
                                                                    e.target.checked ? 'Yes' : 'No'
                                                                  )}

                                                              />



                                                          )
                                                        } else if (buyersQuestion.questionType == 6) {
                                                          return (
                                                            <div>
                                                             <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 7) {
                                                          return (
                                                            <div>
                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 8) {
                                                          return (
                                                            <div>
                                                              <textarea name={`questionAnswers.${buyersQuestion.id}.answersOption`} className="form-control" onChange={handleChange} />

                                                            </div>
                                                          )
                                                        } else {
                                                          return (
                                                            <div></div>
                                                          )
                                                        }
                                                      })()}



                                                    </FormGroup>
                                                  </div>



                                                )}



                                    </div>

                                    <hr className="mt-2 mb-3" />


                            <Row className="mb15">
                                <Col sm="12" md="6">
                                    <SaveButton>Save Questions</SaveButton>

                                </Col>

                                <Col
                                    sm="12"
                                    md="6"
                                    className="text-end"
                                >


                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                )}
            </Formik>
            </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
            </div>
            </React.Fragment>
           
            
        );
    }
}
ConatctInvestorQuestionAnswer.propTypes = {
  match: PropTypes.object,
  onGetContactDetail: PropTypes.object,
  contactDetail: PropTypes.object
}
const mapStateToProps = ({ contact }) =>
    ({
      contactDetail: contact.contactDetail,
     
    });
const mapDispatchToProps = dispatch => ({
  onGetContactDetail: id => dispatch(getContactDetail(id)),
});
export default connect(mapStateToProps,mapDispatchToProps)(ConatctInvestorQuestionAnswer)
import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import Dropzone from "react-dropzone"
import { addProjectGallery, getProjectGallary, deleteProjectGallery, getProjectUUIDAction } from "../../../store/projects/actions"
import { BlobServiceClient } from '@azure/storage-blob';
import { azureConfig } from "../../../helpers/azureConfig";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Form
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";


class ProjectsAssign extends Component {
  constructor(props) {
    super(props)
    //this.imageInputRef = React.useRef();
    this.state = {
     
      selectedFiles: [],
      image:'',
      activeFilter:'All'
      
    }
  
   
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (image) => {
    this.setState({ image: image });
    this.setState({ deleteModal: true });
  };

  handleDeleteGallery = () => {
    const { onDeleteGallery } = this.props;
    const { image } = this.state;
    //console.log(document);
    if (image.id !== undefined) {
      onDeleteGallery(image);
      this.setState({ deleteModal: false });
    }
  };
  handleAcceptedFiles = async (acceptedFiles) => {
    const filesWithPreview = acceptedFiles.map(file => {
      file.preview = URL.createObjectURL(file);
      file.formattedSize = this.formatBytes(file.size);
      return file;
    });

    this.setState({ selectedFiles: filesWithPreview });

    filesWithPreview.forEach(file => this.uploadFileToAzure(file));
  };

  uploadFileToAzure = async (file) => {
    const sasToken = azureConfig.sasToken
    const containerName = azureConfig.containerName;
    const accountName = azureConfig.storageAccountName;
   

    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net?${sasToken}`
    );
    const fileName = `${Date.now()}_${file.name}`;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    try {
      await blockBlobClient.uploadBrowserData(file);
    const fileUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}`;
    const data = {
        'projectId': parseInt(this.props.match.params.id),
        'agentId': parseInt(localStorage.getItem('userId')),
        'projectGalleryTitle' : fileName,
        'projectGalleryLink' : fileUrl
    }
    this.props.addProjectGallery(data, this.props.history);
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage', error);
    }
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

 
  componentDidMount() {
    const { match: { params }, onGetProjectGallery,ongetProjectUUIDAction }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectGallery(params.id);
      ongetProjectUUIDAction(params.projectId);
    }else{
      this.props.history.push('/my-project')
    }
  }
 
  render() {
    const { images, projectData } = this.props;
    const { deleteModal, activeFilter} = this.state;
    //console.log(this.state.files);
      return (
      <React.Fragment>
            {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteGallery}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

<MetaTags>
  <title>Campaigns | Realty Space</title>
</MetaTags>
<Container fluid className="ps-0 ">
      <Row>
          <Col sm='2' className="pe-0" >
          <ProjectSidebar/>
          </Col>
      <Col sm='10' className="ps-0">
      <Row className="project-header">
        <Col sm="7">
      <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Gallery")} />
      </Col>
      <Col sm="4">
      <h2 className="">{this.props.t( projectData.projectName)}</h2>
      </Col>
      </Row>
      <SetupMenu />
        
      <Card>
                          <CardBody>

                           
                            <Row>
                              <Col lg="12">
                                <Nav tabs className="projectTab projectNav">
                                <NavItem>
                                  <NavLink
                                    className={`nav-link ${activeFilter === 'All' ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick('All')}
                                  >
                                    <span className="d-none d-sm-block">{this.props.t("All")}</span>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={`nav-link ${activeFilter === 'Branch 1' ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick('Branch 1')}
                                  >
                                    <span className="d-none d-sm-block">{this.props.t("Branch 1")}</span>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={`nav-link ${activeFilter === 'Branch 2' ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick('Branch 2')}
                                  >
                                    <span className="d-none d-sm-block">{this.props.t("Branch 2")}</span>
                                  </NavLink>
                                </NavItem>
                                
                                 
                                </Nav>

                              </Col>

                            </Row>
                            <div className="table-responsive">
                              <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                <thead className="">
                                  <tr>

                                    <th></th>
                                    <th>Agent Name</th>
                                    <th>Phone</th>
                                    <th>Branch </th>


                                    <th>Start date</th>
                                   
                                  </tr>
                                </thead>
                                <tbody>
                                        <tr>
                                            <td>
                                            <div className="form-check font-size-16">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"

                                  />
                                  <label
                                    className="form-check-label"

                                  >
                                    &nbsp;
                                  </label>
                                </div>
                                            </td>
                                            <td>Mohit Grover</td>
                                            <td>1234567890</td>
                                            <td>Pickering</td>
                                            <td>10th Jan, 2018</td>
                                        </tr>
                               
                                </tbody>
                              </table>
                            </div>
                          </CardBody>
                        </Card>
        </Col>
        </Row>  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsAssign.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  match:PropTypes.object,
  addProjectGallery:PropTypes.func,
  images:PropTypes.array,
  onDeleteGallery:PropTypes.func,
  onGetProjectGallery:PropTypes.func,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  loading:PropTypes.object
}

const mapStateToProps = ({ Project }) => ({
  images: Project.images,
  projectData: Project.projectData,
  loading:Project.loading
})

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onGetProjectGallery:(projectId) => dispatch(getProjectGallary(projectId)),
  onDeleteGallery: (id) => dispatch(deleteProjectGallery(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsAssign)))
import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import axios from "axios"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {Row,Col, CardBody, Card, CardText, Container,FormGroup, CardTitle, Input, Label,  Offcanvas, OffcanvasHeader, OffcanvasBody,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, } from "reactstrap"
  import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
  import SaveButton from "components/buttons/save";
import RsAdNew from "components/buttons/Adnew";
import RSBarChart from "components/charts/RSBarChart";
import RSPieChart from "components/charts/RSPieChart";
class agentDashoard extends Component {
  constructor(props) {
    super(props)
    this.state = {

      agents:[]
      
    }
    this.addNewCanvas = this.addNewCanvas.bind(this);
  }
  addNewCanvas() {
    this.setState({ isAddNew: !this.state.isAddNew });
  }
  componentDidMount() {
   
  }
  render() {
    const barchartdata = {
      series: [
        {
          name: "Clients",
          data: [3, 8, 16],
        },
        {
          name: "Marketing Spend",
          data: [6, 14, 18],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
      
        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8", "#41B8D5"],
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar"
          ],
        },
      },
    };
    const leadSourceData = {
      series: [20, 20, 20, 20, 20],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["Item 5", "Google", "Facebook", "Item 3",  "Item 4"],
        colors: ["#31356E", "#6CE5E8", "#41B8D5", "#2D8BBA", "#2F5F98"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Broker Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row className="project-header mb20">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Dachboard")}
              breadcrumbItem={this.props.t("Agent")}
            />
            </Col>
            <Col xl="5">
            {/* <WelcomeComp /> */}
            <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                 
            

                  <RsAdNew to="#" onClick={ this.addNewCanvas } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
                    
                  </div>
                 
                </div>
            </Col>
            </Row>

<Row className="mb20">
            <Col lg={12}>
            <div className="card">
            <div className="card-body">
                <div className="card-title mb-4 float-start">Top 5 perfoming agents</div>
                <table
                      id=""
                      className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Branch</th>
                            <th>#Office leads</th>
                            <th>#Clients</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mohit Grover</td>
                            <td>1234567890</td>
                            <td>mohit@esimplified.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                            {/* <td>
                            <UncontrolledDropdown direction="up">
                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                      Action <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem tag={Link} to="/agent-detail">Detail</DropdownItem>
                                      <DropdownItem tag={Link} to="#">Edit</DropdownItem>
                                      <DropdownItem href="#">Delete</DropdownItem>


                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                            </td> */}
                          </tr>
                        </tbody>
                </table>
                </div>
            </div>
            </Col>         
            </Row>
              <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                  <div className="card-title mb-4 float-start">Agents List</div>
                  <table
                      id=""
                      className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Branch</th>
                            <th>#Office leads</th>
                            <th>#Clients</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mohit Grover</td>
                            <td>1234567890</td>
                            <td>mohit@esimplified.ca</td>
                            <td>Pickering</td>
                            <td>33</td>
                            <td>18</td>
                            <td>
                            <UncontrolledDropdown direction="up">
                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                      Action <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    <DropdownItem tag={Link} to="/agent-detail">Performance</DropdownItem>
                                        <DropdownItem tag={Link} to="#">Edit</DropdownItem>

                                      <DropdownItem href="#">Delete</DropdownItem>


                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                            </td>
                          </tr>
                        </tbody>
                </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          
          </Container>
        </div>


        <Offcanvas
              isOpen={this.state.isAddNew}
              direction="end"
              toggle={this.addNewCanvas}
            >
              <OffcanvasHeader toggle={this.addNewCanvas}>
              Add Agent
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:  '',
                  
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    agentId: Yup.string().required("This Field in required"),
                  })}

                  onSubmit={this.handleTransferSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Name")}
                            </Label>
                            <Field
                                        name="eventTitle"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Address")}
                            </Label>
                            <Field
                                        name="Address"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Mobile")}
                            </Label>
                            <Field
                                        name="Mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Secondary phone")}
                            </Label>
                            <Field
                                        name="Mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Email")}
                            </Label>
                            <Field
                                        name="Mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Email")}
                            </Label>
                            <Field
                                        name="Mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Branch")}
                            </Label>
                            <Field
                                        name="Mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Start date")}
                            </Label>
                            <Field
                                        name="Mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="transferNote">
                              Notes
                            </Label>
                            <textarea
                                    name="transferNote"
                                    onChange={handleChange}
                                    type="textarea"

                                    className="form-control"

                                    id="transferNote"

                                  />
                             <ErrorMessage
                              name="transferNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                       

                      </Row>
                      <div><SaveButton>Save</SaveButton></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
      </React.Fragment>
    )
  }
}
agentDashoard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(agentDashoard))


import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import SaveButton from 'components/buttons/save';
import { API_URL } from "../../../helpers/app_url";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import ContactDetailMenu from '../menu/detail-menu'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getContactDetail } from "../../../store/actions";
import ContactData from '../forms/contact-data';
class ContactAdditionProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titles:[]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        const { match: { params }, onGetContactDetail} = this.props;
        if (params && params.id) {
        onGetContactDetail(params.id);
        }
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        
          axios.get( API_URL + `QuestionAnswer/allforagenttype`, {
            params: {
              forType: 1,

            }, headers: { Authorization: str }
          })
            .then(res => {
              const buyersQuestions = res.data;
              this.setState({ buyersQuestions });
            });
            axios.get( API_URL + `ContactQuestionAnswer/all`, {
              params: {
                LeadId: this.props.match.params.id,
  
              }, headers: { Authorization: str }
            })
              .then(res => {
                const buyerAnswers = res.data;
                this.setState({ buyerAnswers });
              });

              axios.get(API_URL + `FieldValue/allbyname`, {
                params: {
                  fieldName: 'Agent:Title',
                }, headers: { Authorization: str }
              })
                .then(res => {
                  const titles = res.data;
                  this.setState({ titles });
                }); 

         
      }
      handleSubmit(values) {
        const formattedData = {
            buyerQuestionAnswers: this.state.buyersQuestions.map(question => ({
                leadId: parseInt(this.props.match.params.id),
                questionAnswerId: question.id || 0,
                answersOption: values.questionAnswers[question.id]?.answersOption || '',
                isActive: true
            }))
            .filter(item => typeof item.answersOption === 'string' && item.answersOption.trim() !== '')
        };
       // console.log(formattedData);
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        fetch(`${API_URL}ContactQuestionAnswer/bulkadd`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': str,
          },
          body: JSON.stringify(formattedData)
      })
      .then(response => response.json())
      .then(data => {
          console.log('Success:', data);
      })
      .catch(error => {
          console.error('Error:', error);
      });
    }
    
   
  


    render() {
        const {  buyerAnswers } = this.state;
        const {contactDetail } = this.props;
        return (
          <React.Fragment>
          <ToastContainer autoClose={2000} />
          <div className="page-content project-page-content">
            <MetaTags>
              <title>Contact Detail | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0">
           <Row>
            <Col sm="3" className="pe-0">
                <ContactData />
            </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
                <Breadcrumbs
                  title="Contact"
                  breadcrumbItem="Contact Detail"
                />
           
            </Col>
            <Col sm='4'>
            <h2>{contactDetail.firstName + " " + contactDetail.lastName }</h2>
            </Col>
            </Row>
                 
            <ContactDetailMenu /> 
            
          
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    //clientId: this.props.match.params.id,
                    // secondaryTitle: (this.secondaryTitle && this.state.secondaryTitle) || "",
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    secondaryTitle : Yup.string().required("This Field is Required"),
                    secondaryContactFName :Yup.string().max(100,'Only 100 Characters are allowed').required("This Field is Required"),
                    secondaryContactLName :Yup.string().max(100,'Only 100 Characters are allowed') ,
                    secondaryMobile :Yup.string().min(10,"Only 10 Digits are allowed").max(10,'Only 10 Characters are allowed').required("This Field is Required") ,
                    secondaryEmail :Yup.string().max(100,'Only 100 Characters are allowed').email().required("This Field is Required") ,
                    secondaryOrganization :Yup.string() ,
                    secondaryJobTitle :Yup.string() ,
                    secondaryOffice :Yup.string().min(10,"Only 10 Digits are allowed").max(10,'Only 10 Digits are allowed') ,
                    secondaryPhoneExt :Yup.string().min(3,"Digits should be more than 3").max(6,'Only 6 Digits are allowed') ,
                    secondaryIndustry :Yup.string().max(100,'Only 100 Characters are allowed') ,
                    // dateTitle: Yup.string().required("This is Required"),
                    // dateMonth: Yup.string().required("This is Required"),
                    // dateDay: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleImportantDateSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Card> 
                    <CardBody>
                    <h5 className="card-title">Secondary Contact Details</h5>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field as="select" name="secondaryTitle" className={
                                "form-control" +
                                (errors.secondaryTitle && touched.secondaryTitle
                                  ? " is-invalid"
                                  : "")
                              } onChange={handleChange}>
                            <option value="">Select</option>
                            {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="secondaryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryContactFName">
                              {this.props.t("First Name*")}
                            </Label>
                            <Field
                              name="secondaryContactFName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryContactFName && touched.secondaryContactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryContactFName"
                            />
                             <ErrorMessage
                              name="secondaryContactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryContactLName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="secondaryContactLName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryContactLName && touched.secondaryContactLName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryContactLName"
                            />
                             <ErrorMessage
                              name="secondaryContactLName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryMobile">
                              {this.props.t("Mobile*")}
                            </Label>
                            <Field
                              name="secondaryMobile"
                              onChange={handleChange}                            
                              type="number"
                              
                              className={
                                "form-control" +
                                (errors.secondaryMobile && touched.secondaryMobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryMobile"
                            />
                             <ErrorMessage
                              name="secondaryMobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                  
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="secondaryEmail">
                              {this.props.t("Email*")}
                            </Label>
                            <Field
                              name="secondaryEmail"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryEmail && touched.secondaryEmail
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryEmail"
                            />
                             <ErrorMessage
                              name="secondaryEmail"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                 
                        </Row>
                           
                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOrganization">
                              {this.props.t("Company Name")}
                            </Label>
                            <Field
                              name="secondaryOrganization"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryOrganization && touched.secondaryOrganization
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryOrganization"
                            />
                             <ErrorMessage
                              name="secondaryOrganization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryJobTitle">
                              {this.props.t("Job Title")}
                            </Label>
                            <Field
                              name="secondaryJobTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryJobTitle && touched.secondaryJobTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryJobTitle"
                            />
                             <ErrorMessage
                              name="secondaryJobTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryOffice">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="secondaryOffice"
                              onChange={handleChange}                            
                              type="number"
                              
                              className={
                                "form-control" +
                                (errors.secondaryOffice && touched.secondaryOffice
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryOffice"
                            />
                             <ErrorMessage
                              name="secondaryOffice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryPhoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="secondaryPhoneExt"
                              onChange={handleChange}                            
                              type="number"
                              
                              className={
                                "form-control" +
                                (errors.secondaryPhoneExt && touched.secondaryPhoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryPhoneExt"
                            />
                             <ErrorMessage
                              name="secondaryPhoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="secondaryIndustry">
                              {this.props.t("Industry")}
                            </Label>
                            <Field
                              name="secondaryIndustry"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.secondaryIndustry && touched.secondaryIndustry
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="secondaryIndustry"
                            />
                             <ErrorMessage
                              name="secondaryIndustry"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>
             
             </CardBody>
                </Card>
</Form>
                  )}
                  </Formik>

                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    //clientId: this.props.match.params.id,
                    agentId: localStorage.getItem('userId'),
                    dateTitle: (this.state && this.state.dateTitle) || "",
                    dateMonth: (this.state && this.state.dateMonth) || "",
                    dateDay: (this.state && this.state.dateDay) || "",
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                    dateTitle: Yup.string().required("This is Required"),
                    dateMonth: Yup.string().required("This is Required"),
                    dateDay: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleImportantDateSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => 
                    {const selectedMonth = values.dateMonth ;
                    const selectedYear = new Date().getFullYear();
                    const daysInMonth = selectedMonth ? new Date(selectedYear, selectedMonth, 0).getDate() : 31;
                    return(
                    <Form
                      className="needs-validation"
                    >
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Important dates </h5>
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateTitle">
                              {this.props.t("Type of date*")}
                            </Label>
                            <Field
                              name="dateTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.dateTitle && touched.dateTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="dateTitle"
                            />
                             <ErrorMessage
                              name="dateTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateMonth">
                              {this.props.t("Month*")}
                            </Label>
                            <Field as="select" name="dateMonth" className={`form-control${errors.dateMonth && touched.dateMonth ? " is-invalid" : ""}`} onChange={(e) => {
                              handleChange(e);
                              const selectedMonth = parseInt(e.target.value, 10);
                              // setFieldValue("dateDay", ''); // Reset day value
                              setFieldValue("dateMonth", e.target.value); // Set month value
                            }}>
                              <option value="">Select</option>
                              {[...Array(12)].map((_, index) => (
                                <option key={index + 1} value={index + 1}>{this.props.t(new Date(2000, index, 1).toLocaleString(undefined, { month: 'long' }))}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="dateMonth" component="div" className="invalid-feedback" />
                          
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="dateDay">
                              {this.props.t("Day*")}
                            </Label>
                            <Field as="select" name="dateDay" className={`form-control${errors.dateDay && touched.dateDay ? " is-invalid" : ""}`} onChange={handleChange}>
                                <option value="">Select</option>
                                {[...Array(daysInMonth)].map((_, index) => (
                                  <option key={index + 1} value={index + 1}>{index + 1}</option>
                                ))}
                              </Field>
                              <ErrorMessage name="dateDay" component="div" className="invalid-feedback" />
        
                          </FormGroup>

                          </Col>
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                   
                   <Row>
                    <Col sm="12">
                    <h5 className="card-title">Importants Date</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Title</th>
								<th style={{width:"80%"}}>Month</th>
                <th style={{width:"80%"}}>Day</th>             
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
						
						  </table>
						</div>
                    </Col>
                   </Row>
             </CardBody>
                </Card>
  </Form> )}
  }
                </Formik>

                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    
                    ///clientId: this.props.match.params.id,
                    childName: (this.state && this.state.dateTitle) || "",
                    birthDay: (this.state && this.state.birthDay) || "",
  
                  }}
                  validationSchema={Yup.object().shape({
                    childName: Yup.string().required("This is Required"),
                    birthDay: Yup.string().required("This is Required"),
                  })}

                  onSubmit={this.handleChildSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Add children</h5>
                     <Row>
                          <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="childName">
                              {this.props.t("Child Name*")}
                            </Label>
                            <Field
                              name="childName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.childName && touched.childName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="childName"
                            />
                             <ErrorMessage
                              name="childName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                          </Col>

                          <Col sm="3">
                          <FormGroup className="mb15">

                              <Label htmlFor="birthDay">
                                {this.props.t("Brith Day*")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.birthDay && touched.birthDay
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                
                                onChange={(value) => setFieldValue('birthDay', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                              <ErrorMessage
                                name="birthDay"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>

                          </Col>

                         
                          <Col sm="1">
                          <button type="submit" className="btn btn-primary mt-27"><i className=" bx bx-plus-medical"></i></button>

                          </Col>
                    </Row>           
                    <Row>
                    <Col sm="12">
                    <h5 className="card-title">Childs</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Name</th>
								<th style={{width:"80%"}}>BirthDay</th>            
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
						
						  </table>
						</div>
                    </Col>
                   </Row>
             </CardBody>
                </Card>
  </Form>
  )}
  </Formik>
            
            </Col>
            </Row>
            </Container>
            </div>
            </React.Fragment>
           
            
        );
    }
}
ContactAdditionProfile.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetContactDetail: PropTypes.object,
  contactDetail: PropTypes.object
}
const mapStateToProps = ({ contact }) =>
  ({
    contactDetail: contact.contactDetail,
   
  });
const mapDispatchToProps = dispatch => ({
  onGetContactDetail: id => dispatch(getContactDetail(id)),
});
export default  withRouter(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ContactAdditionProfile)))
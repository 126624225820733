import { API_URL } from "./app_url"
export const POST_LOGIN = API_URL + "user/login"
export const GET_USER_DETAIL_API_URL = "AgentExt/"
export const UPDATE_USER_CURRENCY_API_URL = "AgentExt/updateagentcurrency"
export const POST_EMAIL_SINGNATURE_API_URL = "SystemEmailSignatureTemplate"
export const GET_EMAIL_SINGNATURE_API_URL = "SystemEmailSignatureTemplate/allAgent"
export const POST_SOCAIL_MEDIA_API_URL ="AgentSocialMediaLink/bulkadd"
export const UPDATE_SOCAIL_MEDIA_API_URL ="AgentSocialMediaLink/bulkupdate"
export const GET_SOCAIL_MEDIA_API_URL ="AgentSocialMediaLink/allproject"

export const GET_LEADS_API_URL = API_URL + "Lead/allforagent"
export const UPDATE_LEAD_API_URL =API_URL + "Lead"

export const POST_BUILDER = API_URL + "BuilderExt"
export const GET_BUILDERS = API_URL + "BuilderExt/allforagent"
export const GET_BUILDER_PROJECT_API = "Project/allforbuilder"
export const GET_BUILDER_DETAIL = API_URL + "BuilderExt/id/"
export const DELETE_BUILDER_API_URL = API_URL + "Builder/"
export const GET_CONTACT_DETAIL = API_URL + "Contact/"
export const POST_CONTACT = API_URL + "ContactExt/"

export const POST_DEPART = API_URL + "Contact/"


export const GET_PTYPE = API_URL + "ProjectType/all"


export const POST_PROJECT = API_URL + "Project"
//export const GET_PROJECT_UUID_URL = API_URL + "Project/id/"
export const GET_PROJECT_UUID_URL = API_URL + "ProjectExt/guid/?projectId="
//export const GET_PROJECT_API_URL = API_URL + "ProjectExt/allforagent" 
export const GET_PROJECT_API_URL = API_URL + "ProjectExt/latestforagentnbroker"
//export const POST_PROJECT_FEATURE_API_URL =API_URL + "ProjectFeature"
export const POST_PROJECT_FEATURE_API_URL =API_URL + "ProjectAdditionalFeature"
export const DELETE_PROJECT_FEATURE_API_URL =API_URL + "ProjectAdditionalFeature/"
//export const UPDATE_PROJECT_FEATURE_API_URL = API_URL + "ProjectFeature"
export const UPDATE_PROJECT_FEATURE_API_URL = API_URL + "ProjectAdditionalFeature"
export const UPDATE_PROJECT_FEATURE_STANDARD_API_URL = API_URL + "ProjectFeature/updatefeature"

//export const GET_PROJECT_FEATURE_API_URL =API_URL + "ProjectFeature/all"
export const GET_PROJECT_FEATURE_API_URL =API_URL + "ProjectAdditionalFeature/all"
export const GET_PROJECT_FEATURE_STANDARD_API_URL =API_URL + "ProjectAdditionalFeature/allwithstandard"
//export const GET_PROJECT_FEATURE_STANDARD_API_URL =API_URL + "ProjectFeature/all"
export const POST_PROJECT_CONTACT_API_URL = API_URL + "ProjectContact"
export const GET_PROJECT_CONTACT_API_URL = API_URL + "ProjectContactExt/all"
export const UPDATE_PROJECT_CONTACT_API_URL =API_URL + "ProjectContact"
export const DELETE_PROJECT_CONTACT_API_URL = API_URL + "ProjectContact"


export const POST_PROJECT_DOCUMENT_API_URL = API_URL + "ProjectDocument"
export const GET_PROJECT_DOCUMENT_API_URL = API_URL + "ProjectDocument/allproject"
export const DELETE_PROJECT_DOCUMENT_API_URL = API_URL + "ProjectDocument/"

export const POST_PROJECT_MARKET_API_URL = API_URL + "ProjectMarketing"
export const GET_PROJECT_MARKET_API_URL = API_URL + "ProjectMarketing/all"
export const UPDATE_PROJECT_MARKET_API_URL =API_URL + "ProjectMarketing"
export const DELETE_PROJECT_MARKET_API_URL = API_URL + "ProjectMarketing/"


export const DELETE_PROJECT_API_URL = API_URL + "Project/"
export const UPDATE_PROJECT_API_URL = API_URL + "Project"




export const POST_PROJECT_LINK_API_URL      = API_URL + "ProjectLink"
export const UPDATE_PROJECT_LINK_API_URL    = API_URL + "ProjectLink"
export const GET_PROJECT_LINK_API_URL       = API_URL + "ProjectLink/all"
export const DELETE_PROJECT_LINK_API_URL    = API_URL + "ProjectLink/"

export const POST_PROJECT_GALLERY_API_URL = API_URL + "ProjectGallery"
export const GET_PROJECT_GALLERY_API_URL = API_URL + "ProjectGallery/allproject"
export const DELETE_PROJECT_GALLERY_API_URL = API_URL + "ProjectGallery/"
//ProjectOpenHouse
export const POST_PROJECT_OPEN_HOUSE_FILE_API_URL = API_URL + "ProjectOpenHouseFile"
export const GET_ALL_PROJECT_OPEN_HOUSE_FILE_API_URL = API_URL + "ProjectOpenHouseFile/all"
export const POST_PROJECT_OPEN_HOUSE_API_URL = API_URL + "ProjectOpenHouse"
export const GET_ALL_PROJECT_OPEN_HOUSE_API_URL = API_URL + "ProjectCampaignLead/allforproject"
export const GET_PROJECT_OPEN_HOUSE_API_URL = API_URL + "ProjectOpenHouse"
export const DELETE_PROJECT_OPEN_HOUSE_API_URL = API_URL + "ProjectOpenHouse/"
export const UPDATE_PROJECT_OPEN_HOUSE_API_URL = API_URL + "ProjectOpenHouse"


//ProjectDownloadPrice
export const POST_PROJECT_DOWNLOAD_PRICE_FILE_API_URL = API_URL + "ProjectDocumentPrice"
export const POST_PROJECT_DOWNLOAD_PRICE_API_URL = API_URL + "ProjectDocumentPrice"
export const GET_ALL_PROJECT_DOWNLOAD_PRICE_API_URL = API_URL + "ProjectDocumentPrice/all"
export const GET_PROJECT_DOWNLOAD_PRICE_API_URL = API_URL + "ProjectDocumentPrice"
export const DELETE_PROJECT_DOWNLOAD_PRICE_API_URL = API_URL + "ProjectDocumentPrice/"
export const UPDATE_PROJECT_DOWNLOAD_PRICE_API_URL = API_URL + "ProjectDocumentPrice"


export const POST_QUEST = API_URL + "ContactQuestionAnswer/bulkadd"

export const POST_CONTACT_NOTE = API_URL + "ContactActivities/ContactAddNote"


export const POST_CONTACT_EMAIL1 = API_URL + "ContactActivities/ContactLogEmailSend"

export const POST_CONTACT_EMAIL2 = API_URL + "ContactActivities/ContactLogEmailReceived"

export const POST_CONTACT_EMAIL3 = API_URL + "ContactActivities/ContactLogEmailBouncedBack"


export const POST_CONTACT_CALL1 = API_URL + "ContactActivities/ContactCallLogNoAnswer"
export const POST_CONTACT_CALL2 = API_URL + "ContactActivities/ContactCallLogVoiceMail"
export const POST_CONTACT_CALL3 = API_URL + "ContactActivities/ContactCallLogBadNumber"

export const GET_NOTE_LOG_CONTACT = API_URL + "ContactActivities/allcalllogactivities"
export const GET_EMAIL_LOG_CONTACT = API_URL + "ContactActivities/allemaillogactivities"
export const GET_NOTE_TAB_LOG_CONTACT = API_URL + "ContactActivities/alladdnotelogactivities"
export const POST_CONTACT_APP = API_URL + "Appointment"
export const GET_CONTACT_APP_URL = API_URL + "ContactAppointment/all"
export const GET_CONTACT_APP_DETAIL_URL = API_URL + "Appointment/"
export const GET_ALL_CONTACT_ACTIVITY_URL = API_URL + "ContactActivities/allactivities"
export const GET_LOCAL_DATA_URL = API_URL + "LocalizationResource/all"

//Lead

export const POST_LEAD_API_URL = API_URL + "Lead"
export const GET_ALL_LEAD_API_URL = API_URL + "Lead/allforagent"

export const GET_LEAD_DETAIL_API_URL = API_URL + "Lead/id/"

export const POST_LEAD_APP_API = API_URL + "LeadAppointment"

export const GET_LEAD_APP_URL = API_URL + "LeadAppointment/all"

export const POST_LEAD_NOTE_API = API_URL + "LeadActivities/LeadAddNote"

export const POST_LEAD_LIST_API = API_URL + "LeadListing/bulkadd"


export const GET_LEAD_NOTE_API = API_URL + "LeadActivities/alladdnotelogactivities"
export const GET_LEAD_LIST_API = API_URL + "LeadListing/all"


export const POST_LEAD_FOLLOW_API = API_URL + "LeadActivities/LeadNextFollowup"

export const GET_LEAD_FOLLOW_API = API_URL + "LeadActivities/allnextfollowuplogactivities"

export const GET_LEAD_ALL_ACT_API = API_URL + "LeadActivities/allactivities"


export const POST_LEAD_CALLLOG_NO_API = API_URL + "LeadActivities/LeadCallLogNoAnswer"
export const POST_LEAD_CALLLOG_BN_API = API_URL + "LeadActivities/LeadCallLogBadNumber"
export const POST_LEAD_CALLLOG_VC_API = API_URL + "LeadActivities/LeadCallLogVoiceMail"
export const GET_LEAD_CALLLOG_API = API_URL + "LeadActivities/allcalllogactivities"

export const POST_LEAD_EMAILLOG_BB_API = API_URL + "LeadActivities/LeadLogEmailBouncedBack"
export const POST_LEAD_EMAILLOG_ER_API = API_URL + "LeadActivities/LeadLogEmailReceived"
export const POST_LEAD_EMAILLOG_ES_API = API_URL + "LeadActivities/LeadLogEmailSend"

export const GET_LEAD_EMAILLOG_API = API_URL + "LeadActivities/allemaillogactivities"

export const POST_LEAD_SMSLOG_API = API_URL + "LeadActivities/LeadEmailLog"
export const GET_LEAD_SMSLOG_API = API_URL + "LeadActivities/allemaillogactivities"

//Clients

export const GET_CLIENT_LIST_API =      API_URL + "ClientExt/allforagent"
export const GET_CLIENT_DETAIL_API =        API_URL + "ClientExt/id/"
export const POST_CLIENT_MESSAGE_API =      API_URL + "ClientMessage"
export const GET_CLIENT_MESSAGE_API =       API_URL + "ClientMessage/allClient"
export const POST_CLIENT_DOCUMENT_API =     API_URL + "ClientDocument"
export const GET_CLIENT_DOCUMENT_API        =    API_URL + "ClientDocument/allClient"
export const POST_CLIENT_APPOINTMENT_API    =  API_URL + "ClientAppointment"
export const GET_CLIENT_APPOINTMENT_API      = API_URL + "ClientAppointment/all"
export const POST_CLIENT_NOTE_API           = API_URL + "ClientNote"
export const GET_CLIENT_NOTE_API            = API_URL + "ClientNote/all"
export const POST_CLIENT_SELLER_SHOWING_API = API_URL + "ClientSellerShowing"
export const GET_CLIENT_SELLER_SHOWING_API = API_URL + "ClientSellerShowing/all"
export const POST_CLIENT_SELLER_CLOSING_API =API_URL + "ClientSellerClosing"
export const GET_CLIENT_SELLER_CLOSING_API = API_URL + "ClientSellerClosing/all"
export const POST_CLIENT_SELLER_LISTING_API = API_URL + "ClientListing"
export const GET_CLIENT_SELLER_LISTING_API = API_URL + "ClientListing/all"
export const UPDATE_CLIENT_SELLER_LISTING_API = API_URL + "ClientListing"
export const DELETE_CLIENT_SELLER_LISTING_API = API_URL + "ClientListing/"

//export const POST_CLIENT_ADD_LISTING_API = API_URL + "AgentClientPotentialListing/bulkadd"
export const POST_CLIENT_ADD_LISTING_API = API_URL + "AgentClientPotentialListing"

export const GET_CLIENT_ADD_LISTING_API = API_URL + "AgentClientPotentialListing/all"

export const POST_CLIENT_SELLER_OPHOUSE_API = API_URL + "ClientSellerOpenhouse"
export const GET_CLIENT_SELLER_OPHOUSE_API = API_URL + "ClientSellerOpenhouse/all"
export const POST_OP_HOUSE_LEAD_API = API_URL + "ClientOpenhouseLead/submitopenhouselead"


export const POST_BROKER_EVENT_API_URL = API_URL + "BrokerEvent"
export const GET_BROKER_EVENT_API_URL = API_URL + "AgentBrokerEvent/all"


export const POST_BROKER_MESSAGE_API_URL = API_URL + "BrokerMessage"
export const GET_BROKER_MESSAGE_API_URL = API_URL + "AgentBrokerMessage/allBroker"

export const POST_BROKER_RESOURSE_API_URL = API_URL + "BrokerResource"
export const GET_BROKER_RESOURSE_API_URL = API_URL + "BrokerResource/allBroker"



export const POST_QUEST_CLIENT_SELLER_PREF_URL = API_URL + "ClientQuestionAnswer/bulkadd"

export const DELETE_AGENT_ADD_LISTING_API_URL = API_URL + "AgentClientPotentialListing/"
export const UPDATE_AGENT_ADD_LISTING_API_URL = API_URL + "AgentClientPotentialListing"



export const DELETE_CLIENT_DOCUMENT_API = API_URL + "ClientDocument/"

export const DELETE_CLIENT_APPOINTMENT_API = API_URL + "ClientAppointment/"

export const UPDATE_CLIENT_APPOINTMENT_API = API_URL + "ClientAppointment"

export const UPDATE_CLIENT_DOCUMENT_API = API_URL + "ClientDocument"

export const DELETE_CLIENT_NOTE_API = API_URL + "ClientNote/"

export const UPDATE_CLIENT_NOTE_API = API_URL + "ClientNote"

export const DELETE_CLIENT_OPENHOUSE_API = API_URL + "ClientSellerOpenhouse/"
export const UPDATE_CLIENT_OPENHOUSE_API = API_URL + "ClientSellerOpenhouse"

export const UPDATE_CLIENT_SELLER_SHOWING_API = API_URL + "ClientSellerShowing"

export const DELETE_CLIENT_SELLER_SHOWING_API = API_URL + "ClientSellerShowing/"

export const UPDATE_CLIENT_SELLER_CLOSING_API = API_URL + "ClientSellerClosing"

export const DELETE_CLIENT_SELLER_CLOSING_API = API_URL + "ClientSellerClosing/"

export const POST_CLIENT_BUYER_PREF_URL = API_URL + "ClientQuestionAnswer/bulkadd"

export const GET_CLIENT_UPCOMING_ACTIVITIES_API = API_URL + "AgentClient/allagentupcomingactivities"
export const GET_CLIENT_RECENT_ACTIVITIES_API = API_URL + "AgentClient/allagentrecentactivities"

export const  CREATE_CLIENT_IMPORTANT_DATE_URL =API_URL + "ClientImportantDate"
export const  GET_CLIENT_IMPORTANT_DATE_URL =API_URL + "ClientImportantDate/all"
export const DELETE_CLIENT_IMPORTANT_DATE_URL = API_URL + "ClientImportantDate/"
export const  CREATE_CLIENT_CHILD_URL =API_URL + "ClientChild"
export const  GET_CLIENT_CHILD_URL =API_URL + "ClientChild/all"
export const UPDATE_CLIENT_PROFILE_API = API_URL + "ClientExt"

export const CREATE_NEW_CLIENT_URL = API_URL + "ClientExt"

export const CREATE_CLIENT_BUYER_CLOSING_URL = API_URL + "ClientBuyerClosing"
export const GET_CLIENT_BUYER_CLOSING_URL = API_URL + "ClientBuyerClosing/all"
export const DELETE_CLIENT_BUYER_CLOSING_URL = API_URL + "ClientBuyerClosing/"
export const UPDATE_CLIENT_BUYER_CLOSING_URL = API_URL + "ClientBuyerClosing"

export const GET_TEMPLATE_API_URI =API_URL + "UITemplate/all"

export const CREATE_REVIEW_SOURCE_URL = API_URL + "ReviewSourceLink"
export const GET_REVIEW_SOURCE_URL = API_URL + "ReviewSourceLink/allagentReviewSourceLinks"


export const CREATE_APPOINTMENT_LOCATION_TYPE_URL = API_URL + "AppointmentLocationType"
export const GET_APPOINTMENT_LOCATION_TYPE_URL = API_URL + "AppointmentLocationType/allforagent"
export const UPDATE_APPOINTMENT_LOCATION_TYPE_URL = API_URL + "AppointmentLocationType"
export const DELETE_APPOINTMENT_LOCATION_TYPE_URL = API_URL + "AppointmentLocationType/"


export const CREATE_APPOINTMENT_PRIORITY_URL = API_URL + "AppointmentPriority"
export const GET_APPOINTMENT_PRIORITY_URL = API_URL + "AppointmentPriority/allforagent"
export const UPDATE_APPOINTMENT_PRIORITY_URL = API_URL + "AppointmentPriority"
export const DELETE_APPOINTMENT_PRIORITY_URL = API_URL + "AppointmentPriority/"

export const CREATE_APPOINTMENT_TYPE_URL = API_URL + "AppointmentType"
export const GET_APPOINTMENT_TYPE_URL = API_URL + "AppointmentType/allforagent"
export const UPDATE_APPOINTMENT_TYPE_URL = API_URL + "AppointmentType"
export const DELETE_APPOINTMENT_TYPE_URL = API_URL + "AppointmentType/"


export const CREATE_CONTACT_LEVEL_URL = API_URL + "ContactLevel"
export const GET_CONTACT_LEVEL_URL = API_URL + "ContactLevel/allforagent"
export const UPDATE_CONTACT_LEVEL_URL = API_URL + "ContactLevel"
export const DELETE_CONTACT_LEVEL_URL = API_URL + "ContactLevel/"

export const CREATE_CONTACT_STATUS_URL = API_URL + "ContactStatus"
export const GET_CONTACT_STATUS_URL = API_URL + "ContactStatus/allforagent"
export const UPDATE_CONTACT_STATUS_URL = API_URL + "ContactStatus"
export const DELETE_CONTACT_STATUS_URL = API_URL + "ContactStatus/"

export const CREATE_CONTACT_TYPE_URL = API_URL + "ContactType"
export const GET_CONTACT_TYPE_URL = API_URL + "ContactType/allforagent"
export const UPDATE_CONTACT_TYPE_URL = API_URL + "ContactType"
export const DELETE_CONTACT_TYPE_URL = API_URL + "ContactType/"


export const CREATE_LEAD_CLASSIFICATION_URL = API_URL + "LeadClassification"
export const GET_LEAD_CLASSIFICATION_URL = API_URL + "LeadClassification/allforagent"
export const UPDATE_LEAD_CLASSIFICATION_URL = API_URL + "LeadClassification"
export const DELETE_LEAD_CLASSIFICATION_URL = API_URL + "LeadClassification/"


export const CREATE_LEAD_SOURCE_URL = API_URL + "LeadSource"
export const GET_LEAD_SOURCE_URL = API_URL + "LeadSource/allforagent"
export const UPDATE_LEAD_SOURCE_URL = API_URL + "LeadSource"
export const DELETE_LEAD_SOURCE_URL = API_URL + "LeadSource/"


export const CREATE_LEAD_STATUS_URL = API_URL + "LeadStatus"
export const GET_LEAD_STATUS_URL = API_URL + "LeadStatus/allforagent"
export const UPDATE_LEAD_STATUS_URL = API_URL + "LeadStatus"
export const DELETE_LEAD_STATUS_URL = API_URL + "LeadStatus/"

export const CREATE_LEAD_TYPE_URL = API_URL + "LeadType"
export const GET_LEAD_TYPE_URL = API_URL + "LeadType/allforagent"
export const UPDATE_LEAD_TYPE_URL = API_URL + "LeadType"
export const DELETE_LEAD_TYPE_URL = API_URL + "LeadType/"


export const CREATE_NOTE_TYPE_URL = API_URL + "NoteType"
export const GET_NOTE_TYPE_URL = API_URL + "NoteType/allforagent"
export const UPDATE_NOTE_TYPE_URL = API_URL + "NoteType"
export const DELETE_NOTE_TYPE_URL = API_URL + "NoteType/"


export const CREATE_PROJECT_DOC_TYPE_URL = API_URL + "ProjectDocumentType"
export const GET_PROJECT_DOC_TYPE_URL = API_URL + "ProjectDocumentType/allforagent"
export const UPDATE_PROJECT_DOC_TYPE_URL = API_URL + "ProjectDocumentType"
export const DELETE_PROJECT_DOC_TYPE_URL = API_URL + "ProjectDocumentType/"


export const CREATE_PROJECT_STATUS_URL = API_URL + "ProjectStatus"
export const GET_PROJECT_STATUS_URL = API_URL + "ProjectStatus/allforagent"
export const UPDATE_PROJECT_STATUS_URL = API_URL + "ProjectStatus"
export const DELETE_PROJECT_STATUS_URL = API_URL + "ProjectStatus/"

export const CREATE_PROJECT_TYPE_URL = API_URL + "ProjectType"
export const GET_PROJECT_TYPE_URL = API_URL + "ProjectType/allforagent"
export const UPDATE_PROJECT_TYPE_URL = API_URL + "ProjectType"
export const DELETE_PROJECT_TYPE_URL = API_URL + "ProjectType/"

export const CREATE_SERVICE_TYPE_URL = API_URL + "ServiceType"
export const GET_SERVICE_TYPE_URL = API_URL + "ServiceType/allforagent"
export const UPDATE_SERVICE_TYPE_URL = API_URL + "ServiceType"
export const DELETE_SERVICE_TYPE_URL = API_URL + "ServiceType/"


export const CREATE_TASK_PRIORITY_URL = API_URL + "TaskPriority"
export const GET_TASK_PRIORITY_URL = API_URL + "TaskPriority/allforagent"
export const UPDATE_TASK_PRIORITY_URL = API_URL + "TaskPriority"
export const DELETE_TASK_PRIORITY_URL = API_URL + "TaskPriority/"


export const CREATE_TASK_REMINDER_URL = API_URL + "TaskReminder"
export const GET_TASK_REMINDER_URL = API_URL + "TaskReminder/allforagent"
export const UPDATE_TASK_REMINDER_URL = API_URL + "TaskReminder"
export const DELETE_TASK_REMINDER_URL = API_URL + "TaskReminder/"

export const CREATE_TASK_STATUS_URL = API_URL + "TaskStatus"
export const GET_TASK_STATUS_URL = API_URL + "TaskStatus/allforagent"
export const UPDATE_TASK_STATUS_URL = API_URL + "TaskStatus"
export const DELETE_TASK_STATUS_URL = API_URL + "TaskStatus/"


export const CREATE_TASK_TYPE_URL = API_URL + "TaskType"
export const GET_TASK_TYPE_URL = API_URL + "TaskType/allforagent"
export const UPDATE_TASK_TYPE_URL = API_URL + "TaskType"
export const DELETE_TASK_TYPE_URL = API_URL + "TaskType/"


export const CREATE_TASK_URL = API_URL + "Task"
export const GET_TASK_URL = API_URL + "Task/allforagent"
export const UPDATE_TASK_URL = API_URL + "Task"
export const DELETE_TASK_URL = API_URL + "Task/"


export const GET_TEMPLATE_BY_TYPE_API = API_URL + "UITemplate/alltemplatesbytype"
//export const GET_TEMPLATE_BY_ID_API = API_URL + "UITemplate/templatebyid"
export const GET_CAMPAIGNS_API_URL = API_URL + "AgentReferralCampaign/allagentAgentReferralCampaigns"



export const CREATE_LEAD_STAGE_URL = API_URL + "LeadStage"
export const GET_LEAD_STAGE_URL = API_URL + "LeadStage/allforagent"
export const UPDATE_LEAD_STAGE_URL = API_URL + "LeadStage"
export const DELETE_LEAD_STAGE_URL = API_URL + "LeadStage/"


//export const GET_TEMPLATE_BY_PROJECT_ID = API_URL + "ProjectExt/uidfortemplate"

export const GET_TEMPLATE_BY_PROJECT_ID = API_URL + "ProjectExt/uidforprojectmasteremailtemplate"

export const GET_ALL_PROJECT_SEARCH_API_URL = API_URL + "ProjectExt/latestfullforagentnbrokerbyfilter"



export const UPDATE_MPROJECT_STATUS_API_URL = API_URL + "Project/updateprojectactivestatus"

export const UPDATE_PROJECT_WORKSHEET_UNIT_API_URL = "Project/UpdateprojectUnit"

export const POST_PROJECT_MARKET_ALLOCATION_API_URL = API_URL + "ProjectMarketingAllocation"
export const GET_PROJECT_MARKET_ALLOCATION_API_URL = API_URL + "ProjectMarketingAllocation/all"
export const UPDATE_PROJECT_MARKET_ALLOCATION_API_URL = API_URL + "ProjectMarketingAllocation"
export const DELETE_PROJECT_MARKET_ALLOCATION_API_URL = API_URL + "ProjectMarketingAllocation/"


export const POST_PROJECT_MARKET_SPENT_API_URL = API_URL + "ProjectMarketingSpent"
export const GET_PROJECT_MARKET_SPENT_API_URL = API_URL + "ProjectMarketingSpent/all"
export const UPDATE_PROJECT_MARKET_SPENT_API_URL = API_URL + "ProjectMarketingSpent"
export const DELETE_PROJECT_MARKET_SPENT_API_URL = API_URL + "ProjectMarketingSpent/"


export const POST_PROJECT_LEAD_API_URL = API_URL + "ProjectCampaignLead"
export const GET_PROJECT_LEAD_API_URL = API_URL + "ProjectCampaignLead/allforproject"
export const DELETE_PROJECT_LEAD_API_URL = API_URL + "ProjectCampaignLead/"


export const GET_TEMPLATE_BY_ID_API = API_URL + "ProjectEmailTemplate/templatebyid"


export const POST_PROJECT_EMAIL_CAMP_FILTER_URL = API_URL + "ProjectEmailCampaign/submitprojectemailcampaign"

export const POST_PROJECT_SETTING_WORKSHEET_API_URL = API_URL + "ProjectWorkSheet"

export const GET_PROJECT_SETTING_WORKSHEET_API_URL = API_URL + "ProjectWorkSheet/allproject"
export const DELETE_PROJECT_SETTING_WORKSHEET_API_URL = API_URL + "ProjectWorkSheet/"

//export const POST_PROJECT_LEAD_WORKSHEET_API_URL = API_URL + "ProjectProspectEmail/submitprojectprospectemailbulkemaillist"
export const POST_PROJECT_LEAD_WORKSHEET_API_URL = API_URL + "ProjectLeadWorkSheet/SendProjectLeadWorkSheet"

export const POST_LEAD_BOARD_STAGE_API_URL = API_URL + "Lead/quickUpdateLeadStage"

export const POST_LEAD_BOARD_STATUS_API_URL = API_URL + "Lead/quickUpdateLeadStatus"

export const POST_LEAD_CONVERT_CLIENT_API_URL = API_URL + "Lead/LeadConvertToClient"
export const POST_LEAD_CONVERT_CONTACT_API_URL = API_URL + "Lead/LeadConvertToContact"

export const GET_ALL_PROJECT_LEAD_API_URL = API_URL + "ProjectCampaignLead/allforagent"
export const GET_ALL_PROJECT_CLIENT_API_URL = API_URL + "ProjectCampaignClient/allforagent"

export const UPLOAD_TEST_URL_API = "ProjectTestImage"

export const POST_AGENT_GOAL_API_URL ="AgentGoal"
export const GET_AGENT_GOAL_API_URL ="AgentGoal/allAgent"
export const DELETE_AGENT_GOAL_API_URL ="AgentGoal"
export const GET_AGENT_TRANSACTION_API_URL = "AgentClientTransaction/dashboard"
export const CREATE_EMAIL_MASTER_API_URL = "ProjectEmailTemplate"

export const GET_EMAIL_MASTER_API_URL = "ProjectEmailTemplate/alltemplates"

export const GET_TEMPLATE_BY_ID_API_URL = "ProjectEmailTemplate/templatebyid"

export const GET_MASTER_EMAIL_URL = "ProjectExt/uidforprojectmasteremailtemplate"
export const GET_LANDING_MASTER_URL = "ProjectExt/uidforprojectmasterlandingpagetemplate"
export const GET_EVENT_LANDING_MASTER_URL = "ProjectEventInvite/guidforeventmasteremailtemplate"

export const GET_PROJECT_OPEN_HOUSE_FILE_URL = "ProjectOpenHouseFile/allforagent"
export const DELETE_PROJECT_OPEN_HOUSE_FILE_URL = "ProjectOpenHouseFile"


export const GET_PROJECT_DOWNLOAD_PRICE_FILE_URL = "ProjectDocumentPriceFile/allforagent"
export const DELETE_PROJECT_DOWNLOAD_PRICE_FILE_URL = "ProjectDocumentPriceFile"


export const POST_TRANSACTION_TYPE_URL = "TransactionType"
export const GET_TRANSACTION_TYPE_URL = "TransactionType/allforagent"
export const UPDATE_TRANSACTION_TYPE_URL = "TransactionType"
export const DELETE_TRANSACTION_TYPE_URL = "TransactionType/"

export const POST_TRANSACTION_COMM_URL = "TransCommisisonType"
export const GET_TRANSACTION_COMM_URL = "TransCommisisonType/allforagent"
export const UPDATE_TRANSACTION_COMM_URL = "TransCommisisonType"
export const DELETE_TRANSACTION_COMM_URL = "TransCommisisonType/"


export const POST_TRANSACTION_REF_URL = "TransRefType"
export const GET_TRANSACTION_REF_URL = "TransRefType/allforagent"
export const UPDATE_TRANSACTION_REF_URL = "TransRefType"
export const DELETE_TRANSACTION_REF_URL = "TransRefType/"

export const POST_TRANSACTION_STATUS_URL = "TransStatus"
//export const GET_TRANSACTION_STATUS_URL = "TransStatus/allforagent"
export const GET_TRANSACTION_STATUS_URL = "TransStatus/allforagentfortype"
export const UPDATE_TRANSACTION_STATUS_URL = "TransStatus"
export const DELETE_TRANSACTION_STATUS_URL = "TransStatus/"

export const GET_PROJECT_WORKSHEET_LEAD_API_URL = "ProjectLeadWorkSheet/allproject"

export const GET_PROJECT_WORKSHEET_LEAD_PUR_API_URL = "ProjectLeadWorkSheet/getAllPurchasersByProjectLeadWorkSheetById"
export const GET_PROJECT_WORKSHEET_LEAD_UNIT_API_URL = "ProjectLeadWorkSheet/getAllOptionsByProjectLeadWorkSheetById"
export const GET_PROJECT_WORKSHEET_LEAD_DOC_API_URL = "ProjectLeadWorkSheet/getAllPurchaserDocumentByProjectLeadWorkSheetById"

export const POST_PROJECT_INVITE_URL = "ProjectEventInvite"
export const GET_PROJECT_INVITE_URL = "ProjectEventInvite/allforproject"
export const GET_PROJECT_INVITE_DETAIL_URL = "ProjectEventInvite"
export const DELETE_PROJECT_INVITE_URL = "ProjectEventInvite/"

export const GET_PROJECT_INVITE_ATT_URL = "ProjectEventInviteRegistration/allforevent"

export const GET_PROJECT_WORKSHEET_UNIT_INFO_URL = "ProjectLeadWorkSheet"

export const GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_API_URL = "ProjectCampaignLead/allforprojectWorkSheet"


export const POST_NEW_ACTIVITY_API_URL = 'Activity'
export const POST_FILTER_ACTIVITY_API_URL = 'Activity/allfilteredactivities'
export const DELETE_MAIN_ACTIVITY_API_URL = 'Activity'

export const GET_SELLER_SHOWING_DETAIL_API_URL = 'ClientSellerShowing/byid'
export const GET_BUYER_SHOWING_DETAIL_API_URL = 'AgentClientPotentialListing'
export const GET_OPENHOUSE_SHOWING_DETAIL_API_URL = 'ClientSellerOpenhouse/byid'



export const POST_LISTING_TYPE_API_URL = 'ListingType'
export const GET_LISTING_TYPE_API_URL = 'ListingType/all'
export const UPDATE_LISTING_TYPE_API_URL = 'ListingType'
export const DELETE_LISTING_TYPE_API_URL = 'ListingType'

export const POST_LISTING_STATUS_API_URL = 'ListingStatus'
export const GET_LISTING_STATUS_API_URL = 'ListingStatus/all'
export const UPDATE_LISTING_STATUS_API_URL = 'ListingStatus'
export const DELETE_LISTING_STATUS_API_URL = 'ListingStatus'


export const POST_SHOWING_STATUS_API_URL = 'ShowingStatus'
export const GET_SHOWING_STATUS_API_URL = 'ShowingStatus/all'
export const UPDATE_SHOWING_STATUS_API_URL = 'ShowingStatus'
export const DELETE_SHOWING_STATUS_API_URL = 'ShowingStatus'



export const  POST_CLIENT_BUYER_OFFER_URL = "ClientOffer/AddBuyerClientOffer"
export const  UPDATE_CLIENT_BUYER_OFFER_URL = "ClientOffer/UpdateBuyerClientOffer"
export const  GET_CLIENT_BUYER_OFFER_URL = "ClientOffer/GetBuyerClientOffersById"
export const  POST_CLIENT_SELLER_OFFER_URL = "ClientOffer/AddSellerClientOffer"
export const  UPDATE_CLIENT_SELLER_OFFER_URL = "ClientOffer/UpdateSellerClientOffer"
export const  GET_CLIENT_SELLER_OFFER_URL = "ClientOffer/GetSellerClientOffersById"

export const POST_EMAIL_LIST_TYPE_API_URL = "EmailListType"
export const GET_EMAIL_LIST_TYPE_API_URL = "EmailListType/all"
export const UPDATE_EMAIL_LIST_TYPE_API_URL = "EmailListType"
export const DELETE_EMAIL_LIST_TYPE_API_URL = "EmailListType/"

export const POST_EMAIL_LIST_TARGET_API_URL = "EmailListTarget"
export const GET_EMAIL_LIST_TARGET_API_URL = "EmailListTarget/all"
export const UPDATE_EMAIL_LIST_TARGET_API_URL = "EmailListTarget"
export const DELETE_EMAIL_LIST_TARGET_API_URL = "EmailListTarget/"

export const POST_CAMPAIGN_EMAIL_LIST_API_URL = "CampaignEmailList"
export const GET_CAMPAIGN_EMAIL_LIST_API_URL = "CampaignEmailList/allagentCampaignEmailLists"
export const UPDATE_CAMPAIGN_EMAIL_LIST_API_URL = "CampaignEmailList"
export const DELETE_CAMPAIGN_EMAIL_LIST_API_URL = "CampaignEmailList/"
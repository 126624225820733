import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import notes from "../../assets/images/rsicons/notes.png"
import phonelog from "../../assets/images/rsicons/phone-log.png"
import emaillog from "../../assets/images/rsicons/email-log.png"
import prefrence from "../../assets/images/rsicons/prefrence.png"
import { getContactDetail, bqapiError, addBuyerQuest, addTabNote, addTabEmail, 
  addTabCall, getTabCall, getTabEmail, getTabNote, addContactAPP, getAppContatTab, getAppContatDetailTab, getAllContactActivityTab, addContact } from "../../store/contacts/actions"
  import { apiError } from "../../store/actions"
import Moment from 'react-moment';
import "react-rangeslider/lib/index.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import classnames from "classnames"
import { AvField, AvForm, AvCheckboxGroup, AvCheckbox, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentAppModal from "./AppModal";
import ContactEdit from "./forms/contact-edit";
import ContactDetailMenu from "./menu/detail-menu"
import ContactData from "./forms/contact-data";
// import { apiError, addContact,getContactDetail } from "../../store/actions"
class ContactDetail extends Component {
  constructor(props) {
    super(props)
    this.autocomplete = null,
      this.state = {
        col9: false,
        col10: false,
        col11: false,
        col12: true,
        col15: false,
        selectedGroup: null,
        activeTab: "1",
        verticalActiveTab: "1",
        buyersQuestions: [],
        sellerQuestions: [],
        investorQuestions: [],
        buyersData: [],
        selectedvalue: '',
        emailsubmit1: "1",
        emailsubmit2: "2",
        emailsubmit3: "3",
        emailNote: '',

        callsubmit1: "1",
        callsubmit2: "2",
        callsubmit3: "3",
        callNote: '',
        appTypes: [],
        appPriories: [],
        appLocTypes: [],
        logDate: '',
        callLogHistory: [],
        selectedValue: '',
        value: 0,
        viewmodal: false,
        modal: false,
        titles:[],
        statuses : [],
      types : [],
      connects : [],
      countries : [],
      state : this.initialState(),
        
      }
      this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
      this.toggleRightCanvasEdit = this.toggleRightCanvasEdit.bind(this);
      this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
    this.toggle = this.toggle.bind(this);
    this.handelValidApp = this.handelValidApp.bind(this)
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleSubmitNote = this.handleSubmitNote.bind(this)
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this)
    this.handleSubmitCall = this.handleSubmitCall.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this)

  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleRightCanvasEdit() {
    this.setState({ isRightEdit: !this.state.isRightEdit });
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  handleValidSubmit(event, values) {

    this.props.addBuyerQuest(values, this.props.history)
    //console.log(values);
  }

  handleSubmitNote(event, values) {
    this.props.addTabNote(values, this.props.history)
  }

  handleSubmitEmail(value) {
    this.props.addTabEmail(value, this.props.history)
    console.log(value);

  }
  handleChange(e) {

    this.setState({ selectedValue: e.target.value });
  }
  handleInputChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }
  handleSubmitCall(value) {
    //console.log("event: ", event);
    this.props.addTabCall(value, this.props.history)
    //console.log(value);

  }

  handelValidApp(value) {
    this.props.addContactAPP(value, this.props.history)
    //console.log(value)
  }
  handleAppClicks = () => {
    this.toggle();
  };
  handleRangeChange = value => {
    this.setState({
      value: value
    })
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };
  componentDidMount() {
    this.props.apiError("");
    const {
      match: { params }, onGetContactDetail, onGetTabCall, onGetTabEmail, onGetTabNote, onGetAppContact, onGetAllContactAct, onGetAppContactDetail } = this.props;

    if (params && params.id) {
      onGetContactDetail(params.id);
      onGetTabCall(params.id);
      onGetTabEmail(params.id);
      onGetTabNote(params.id);
      onGetAppContact(parseInt(localStorage.getItem("userId")),params.id);
      onGetAppContactDetail(params.id);
      onGetAllContactAct(params.id);
    } else {
      onGetContactDetail(2);

    }
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net//api/QuestionAnswer/allforagenttype', {
      params: {
        forType: 1,

      }, headers: { Authorization: str }
    })
      .then(res => {
        const buyersQuestions = res.data;
        this.setState({ buyersQuestions });
        //console.log('Buyer Question');
        //console.log(buyersQuestions);
      }),
      axios.get('https://rscoreapi.azurewebsites.net//api/QuestionAnswer/allforagenttype', {
        params: {
          forType: 2,

        }, headers: { Authorization: str }
      })
        .then(res => {
          const sellerQuestions = res.data;
          this.setState({ sellerQuestions });
        }),
      axios.get('https://rscoreapi.azurewebsites.net//api/QuestionAnswer/allforagenttype', {
        params: {
          forType: 3,

        }, headers: { Authorization: str }
      })
        .then(res => {
          const investorQuestions = res.data;
          this.setState({ investorQuestions });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactQuestionAnswer/allfortype', {
        params: {
          ContactId: params.id,
          ContactTypeId: 1

        }, headers: { Authorization: str }
      })
        .then(res => {
          const buyersData = res.data;
          this.setState({ buyersData });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appTypes = res.data;
          this.setState({ appTypes });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
          params: {
            fieldName: 'Agent:Title',
          }, headers: { Authorization: str }
        })
          .then(res => {
            const titles = res.data;
            this.setState({ titles });
          }),

          axios.get('https://rscoreapi.azurewebsites.net/api/Country/all',{ headers: { Authorization: str } })
    .then(res => {
      const countries = res.data;
      this.setState({ countries });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/ContactType/all',{  headers: { Authorization: str } })
    .then(res => {
        const types = res.data;
        this.setState({ types });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactStatus/all',{  headers: { Authorization: str } })
    .then(res => {
        const statuses = res.data;
        this.setState({ statuses });
      }),
      axios.get('https://rscoreapi.azurewebsites.net//api/LeadSource/all',{  headers: { Authorization: str } })
      .then(res => {
          const connects = res.data;
          this.setState({ connects });
        })




  }

  initialState() {
    return {
      name: '',
      street_address: '',
      city: '',
      states: '',
      zip_code: '',
      googleMapLink: ''

    }
  }


  // handlePlaceSelect() {
  //   const addressObject = this.autocomplete.getPlace()
  //   const address = addressObject.address_components;
  //   //console.log(addressObject);
  //   this.setState({
  //     name: addressObject.name,

  //   })
  // }

  handlePlaceSelect() {
    const addressObject = this.autocomplete.getPlace()
    const address = addressObject.address_components;
    console.log(addressObject);
    this.setState({
      name: addressObject.name,
      street_address: `${address[0].long_name} ${address[1].long_name}`,
      city: address[2].long_name,
      states: address[4].short_name,
      zip_code: address[6].short_name,
      //googleMapLink: addressObject.url
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }




  callbuildPayload = submitName => {
    return { [`submit${submitName}`]: submitName, remark: this.state.callNote };
  };
  callchangeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  callsubmitHandler = (submitName) => {
    const payload = this.callbuildPayload(submitName);
    //console.log(payload.submit2);
    this.props.addTabCall(payload, this.props.history)
  };


  render() {
    const { value } = this.state
    const { selectedGroup } = this.state;
    const { callLogs, emailLogs, noteLogs, appointments } = this.props;
    const { contactDetail, appDetails, allactivities } = this.props;
    const datafill = this.state.buyersData;
    const { emailNote, emailsubmit1, emailsubmit2, emailsubmit3, callsubmit1, callsubmit2, callsubmit3 } = this.state;
    this.state.buyersQuestions.forEach(function (record) {
      record.selectedvalue = '';
      record.selectedid = 0;
      datafill.forEach(function (buyervalue) {
        if (buyervalue.questionAnswerId == record.id) {
          record.selectedvalue = buyervalue.answersOption;
          record.selectedid = buyervalue.id;
        }

      });
    });

    const selectedValue = this.state.selectedValue;



    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
   
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
   
    return (
      <React.Fragment>
        
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <ToastContainer autoClose={2000} />
            <Row>
              <Col sm='3'>
                <ContactData />
              </Col>
              <Col sm='9'>
              <Row className="project-header">
                <Col sm='8'>
            <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Contact Detail")}
            />
            {/* <Link to="/all-contacts" className="mb-4 text-black" ><div className="mb-2"><img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/> {" "}Back to Contacts </div></Link> */}
            </Col>
            <Col sm='4'>
                <h2>{contactDetail.firstName + " " + contactDetail.lastName }</h2>
            </Col>
            </Row>
            <ContactDetailMenu />
            <Row>
            <Col xl="12">
                             <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10"
                                  >
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "1",
                                  })}
                                  onClick={() => {
                                    this.toggle("1")
                                  }}
                                >
                                  <span className="">{this.props.t("All Activities")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "2",
                                  })}
                                  onClick={() => {
                                    this.toggle("2")
                                  }}
                                >

                                  <span className="">{this.props.t("Meetings")}</span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "3",
                                  })}
                                  onClick={() => {
                                    this.toggle("3")
                                  }}
                                >

                                  <span className="">{this.props.t("Notes")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "6",
                                  })}
                                  onClick={() => {
                                    this.toggle("6")
                                  }}
                                >

                                  <span className="">{this.props.t("Email Logs")}</span>
                                </NavLink>
                              </NavItem>


                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "5",
                                  })}
                                  onClick={() => {
                                    this.toggle("5")
                                  }}
                                >

                                  <span className="k">{this.props.t("Call Logs")}</span>
                                </NavLink>
                              </NavItem>


                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "7",
                                  })}
                                  onClick={() => {
                                    this.toggle("7")
                                  }}
                                >

                                  <span className="">{this.props.t("Referrals")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "8",
                                  })}
                                  onClick={() => {
                                    this.toggle("8")
                                  }}
                                >

                                  <span className="">{this.props.t("Transactions")}</span>
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: this.state.activeTab === "9",
                                  })}
                                  onClick={() => {
                                    this.toggle("9")
                                  }}
                                >

                                  <span className="">{this.props.t("Interested In")}</span>
                                </NavLink>
                              </NavItem>



                            </Nav>
                            <Card>
                            <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                              <TabPane tabId="1">
                                <div className="mb-5 h4 card-title">All Activities</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(allactivities, (allactivity, allactkey) => (

                                    <li className="event-list eventlist" key={"_allact_" + allactkey}>
                                      <div className="event-timeline-dot">
                                       {(() => {
                                                  if(allactivity.activitySubType==8 || allactivity.activitySubType==9 || allactivity.activitySubType==7){
                                                    return (
                                                      <>
                                                       <img src={emaillog} />
                                                      </>
                                                    )
                                                  }else if(allactivity.activitySubType==3 || allactivity.activitySubType==4 || allactivity.activitySubType==5){
                                                    return (
                                                      <>
                                                       <img src={phonelog} />
                                                      </>
                                                    )
                                                  }else{
                                                    return (
                                                      <>
                                                       <img src={notes} />
                                                      </>
                                                    )

                                                  }
                                            })()}
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{allactivity.addedDate}</Moment>

                                            
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ allactivity.activityType+"_"+allactivity.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{allactivity.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>
                              <TabPane tabId="2">
                                <div className="h4 card-title">Set Meeting</div>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    appAjenda: (this.state && this.state.appAjenda) || "",
                                    apptype: '',
                                    appPriority: '',
                                    appointmentLocationType: '',
                                    appADate: new Date(),
                                    appStat: new Date(),
                                    appEnd: edTime,
                                    contactName: contactDetail.firstName + " " + contactDetail.lastName,
                                    contactPhone: contactDetail.mobile,
                                    contactEmail: contactDetail.email,
                                    refId: contactDetail.id
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appAjenda: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    apptype : Yup.number().required("This field is required"),
                                    appPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                  })}

                                  onSubmit={this.handelValidApp}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                    className="needs-validation"
                                  >
                                    <Row>
                                      <Col md="5">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="appAjenda">
                                            {this.props.t("Agenda*")}
                                          </Label>
                                          <input
                                            name="appAjenda"
                                            type="text"
                                            value={values.appAjenda}
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.appAjenda && touched.appAjenda
                                                ? " is-invalid"
                                                : "")
                                            }
                                            id="appAjenda"
                                          />
                                          <ErrorMessage
                                            name="appAjenda"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appADate">
                                            {this.props.t("Date")}
                                          </Label>
                                          <Flatpickr
                                            className={
                                              "form-control" +
                                              (errors.appADate && touched.appADate
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder=""
                                            value={values.appADate}
                                            onChange={(value) => setFieldValue('appADate', value[0])}
                                            options={{
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "Y-m-d",
                                              minDate:'today'
                                            }}
                                          />
                                          <ErrorMessage
                                            name="appADate"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="2">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appStat">
                                            {this.props.t("Start Time")}
                                          </Label>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              value={values.appStat}
                                              onChange={(value) => setFieldValue('appStat', value[0])}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                maxDate: values.appEnd,
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>

                                        </FormGroup>
                                      </Col>
                                      <Col md="2">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appEnd">
                                            {this.props.t("End Time")}
                                          </Label>
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              value={values.appEnd}
                                              onChange={(value) => setFieldValue('appEnd', value[0])}
                                              options={{
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                minDate: values.appStat,
                                              }}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text">
                                                <i className="mdi mdi-clock-outline" />
                                              </span>
                                            </div>
                                          </InputGroup>

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="apptype">
                                            {this.props.t("Appointment Type*")}
                                          </Label>
                                          <Field as="select" name="apptype" className={
                                              "form-control" +
                                              (errors.apptype && touched.apptype
                                                ? " is-invalid"
                                                : "")
                                            }>
                                            <option value="">Select</option>
                                            {
                                              this.state.appTypes
                                                .map(appType =>
                                                  <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                                )
                                            }
                                          </Field>
                                          <ErrorMessage
                                            name="apptype"
                                            component="div"
                                            className="invalid-feedback"
                                          />

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appPriority">
                                            {this.props.t("Appointment Priority*")}
                                          </Label>
                                          <Field as="select" name="appPriority" className={
                                              "form-control" +
                                              (errors.appPriority && touched.appPriority
                                                ? " is-invalid"
                                                : "")
                                            }>
                                            <option value="">Select</option>
                                            {
                                              this.state.appPriories
                                                .map(appPriorie =>
                                                  <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                                )
                                            }
                                          </Field>
                                          <ErrorMessage
                                            name="appPriority"
                                            component="div"
                                            className="invalid-feedback"
                                          />

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">

                                          <Label htmlFor="appointmentLocationType">
                                            {this.props.t("Location Type*")}
                                          </Label>
                                          <Field as="select" name="appointmentLocationType" className={
                                              "form-control" +
                                              (errors.appointmentLocationType && touched.appointmentLocationType
                                                ? " is-invalid"
                                                : "")
                                            }>
                                            <option value=''>Select</option>
                                            {
                                              this.state.appLocTypes
                                                .map(appLocType =>
                                                  <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                                )
                                            }
                                          </Field>
                                          <ErrorMessage
                                            name="appointmentLocationType"
                                            component="div"
                                            className="invalid-feedback"
                                          />

                                        </FormGroup>
                                      </Col>

                                      <Col md="3">
                                        <FormGroup className="mb15">
                                          {(() => {
                                            if (selectedValue == 3) {
                                              return (
                                                <>
                                                  <Label htmlFor="appointmentLocation">
                                                    {this.props.t("Location")}
                                                  </Label>
                                                  <input
                                                    type="text"
                                                    name="appointmentLocation"
                                                    className="form-control"
                                                    id="autocomplete"
                                                  />
                                                </>
                                              )
                                            } else if (selectedValue == 2) {
                                              return (
                                                <>
                                                  <Label htmlFor="appointmentLocation">
                                                    {this.props.t("Phone")}
                                                  </Label>
                                                  <input
                                                    type="text"
                                                    name="appointmentLocation"
                                                    className="form-control"
                                                    id="appointmentLocation"
                                                  />
                                                </>
                                              )
                                            } else {
                                              return (
                                                <>
                                                  <Label htmlFor="appointmentLocation">
                                                    {this.props.t("Online Link")}
                                                  </Label>
                                                  <input
                                                    type="text"
                                                    name="appointmentLocation"
                                                    className="form-control"
                                                    id="appointmentLocation"
                                                  />
                                                </>
                                              )
                                            }
                                          })()}



                                        </FormGroup>
                                      </Col>
                                    </Row>


                                

                                    <div className="clearfix"><SaveButton /></div>
                                  </Form>
                                  )}
                                </Formik>

                                <div className="mb-5 h4 card-title mt-20">Meeting History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(appointments, (appointments, appointmentskey) => (

                                    <li className="event-list eventlist" key={"_app_" + appointmentskey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{appointments.appointmentStartDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"><a  onClick={this.toggleRightCanvas}>{appointments.appointmentTitle}</a></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{appointments.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                               

                              </TabPane>
                              <TabPane tabId="3">
                                <div className="mb-5 h4 card-title">Notes</div>
                                <AvForm className="needs-validation"
                                  method="post"
                                  onValidSubmit={this.handleSubmitNote}
                                >
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="contactNote">
                                          {this.props.t("Add Note*")}
                                        </Label>
                                        <AvField
                                          name="contactNote"

                                          type="textarea"
                                          errorMessage={this.props.t("Enter Note (500 Characters Only)")}
                                          className="form-control"
                                          validate={{ required: { value: true } }}
                                          maxLength={500}
                                          id="contactNote"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix">

                                    {!isEmpty(contactDetail) && (
                                      <AvField type="hidden" name="contactId" value={contactDetail.id} />
                                    )}
                                   <SaveButton /></div>
                                </AvForm>
                                <div className="mb-5 h4 card-title">Notes  History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(noteLogs, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>


                              <TabPane tabId="5">
                                <div className="mb-5 h4 card-title">Call Log</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          actionType: '',
                          callNote:'',
                          contactId: contactDetail.id,
                          logDate: new Date(),
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          actionType: Yup.string().required(
                            "Please Select Action"
                          ),
                          callNote : Yup.string().max(500,"Only 500 characters are allowed")
                        })}

                        onSubmit={this.handleSubmitCall}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            <div className="form-group has-feedback">
                                      <Field type="hidden" name="contactId" id="contactId" value={contactDetail.id}  />
                                      </div>
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="No Answer"  onChange={handleChange}  />
                                          No Answer
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail"  onChange={handleChange} />
                                          Left Voicemail
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number"  onChange={handleChange}  />
                                          Bad Number
                                        </label>
                                        <ErrorMessage
                                              name="actionType"
                                              component="div"
                                              className="text-danger"
                                            />
                                        
                                      </div>
                                      

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Log Date")}
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.logDate && touched.logDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.logDate}
                                              onChange={(value) => setFieldValue('logDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="logDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="callNote">
                                          {this.props.t("Add Message (Optional)")}
                                        </Label>
                                        <textarea
                                          name="callNote"
                                          onChange={handleChange}
                                          type="textarea"
                                         
                                          className={`form-control${errors.callNote && touched.callNote ? " is-invalid" : ""}`}
                                         
                                          id="callNote"

                                        />
                                        <ErrorMessage
                                              name="callNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton /></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(callLogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                              </TabPane>

                              <TabPane tabId="6">
                                <div className="mb-5 h4 card-title">Email Log</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          actionType: '',
                          contactId: contactDetail.id,
                          logDate: new Date(),
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          
                          actionType: Yup.string().required(
                            "Please Select Action"
                          ),
                          emailNote : Yup.string().max(500,"Only 500 characters are allowed")
                        })}

                        onSubmit={this.handleSubmitEmail}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                           
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent"  onChange={handleChange}  />
                                          Email Sent
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Email Received"  onChange={handleChange} />
                                          Email Received
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced"  onChange={handleChange}  />
                                          Email Bounced
                                        </label>
                                        <ErrorMessage
                                              name="actionType"
                                              component="div"
                                              className="text-danger"
                                            />
                                      </div>
                                      

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Log Date")}
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.logDate && touched.logDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.logDate}
                                              onChange={(value) => setFieldValue('logDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="logDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Add Message (Optional)")}
                                        </Label>
                                        <Field
                                         
                                          name="emailNote"
                                          onChange={handleChange}
                                          as="textarea"
                                         
                                          className={`form-control${errors.emailNote && touched.emailNote ? " is-invalid" : ""}`}
                                         
                                          id="emailNote"

                                        />
                                        <ErrorMessage
                                              name="emailNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton /></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emailLogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
                              </TabPane>
                              

                              <TabPane tabId="7">
                                <div className="mb-5 h4 card-title">Referrals</div>
                                <Table>
                                  <thead>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Creation Date</th>
                                    <th>Current Status</th>
                                    <th>Transactions</th>
                                  </thead>

                                </Table>

                              </TabPane>




                            </TabContent>
                            </Card>
            </Col>
            </Row>
             
              </Col>
            </Row>
          
           
          
          </Container>
        </div>

        <Offcanvas
                      isOpen={this.state.isRightEdit}
                      direction="end"
                      toggle={this.toggleRightCanvasEdit}
                      style={{width:800}}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvasEdit}>
                        Update Contact
                      </OffcanvasHeader>
                      <OffcanvasBody>
                        <ContactEdit />

                      </OffcanvasBody>
                      </Offcanvas>
                      
        <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Appointment Detail
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      {!isEmpty(appDetails) && (
                      <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Agenda : {appDetails.appointmentTitle}</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Date : <Moment format="D MMM YY">{appDetails.appointmentStartDate}</Moment></th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Start Time - End Time : <Moment format="hh:mm: A">{appDetails.startTime}</Moment> - <Moment format="hh:mm A">{appDetails.endTime}</Moment></th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Appointment Type : {appDetails.appointmentType}</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Appointment Priority : {appDetails.appointmentPriority}</th>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">Location Type :  {appDetails.appointmentLocationType}</th>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                      )}
                      </OffcanvasBody>
                    </Offcanvas>
      </React.Fragment>
    )
  }
}
ContactDetail.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  t: PropTypes.any,
  match: PropTypes.object,
  onGetContactDetail: PropTypes.object,
  onGetTabCall: PropTypes.func,
  onGetTabEmail: PropTypes.func,
  onGetTabNote: PropTypes.func,
  history: PropTypes.object,
  addBuyerQuest: PropTypes.func,
  addTabNote: PropTypes.func,
  addTabEmail: PropTypes.func,
  addTabCall: PropTypes.func,
  addContactAPP: PropTypes.func,
  onGetAppContact: PropTypes.func,
  onGetAppContactDetail: PropTypes.object,
  onGetAllContactAct:PropTypes.func,
  contactDetail: PropTypes.object,
  callLogs: PropTypes.array,
  emailLogs: PropTypes.array,
  noteLogs: PropTypes.array,
  appointments: PropTypes.array,
  appDetails: PropTypes.object,
  allactivities:PropTypes.array,


}

const mapStateToProps = ({ contact }) => (
  //console.log(contact),
  {
    contactDetail: contact.contactDetail,
    callLogs: contact.callLogs,
    emailLogs: contact.emailLogs,
    noteLogs: contact.noteLogs,
    appointments: contact.appointments,
    allactivities: contact.allactivities,
    appDetails:contact.appDetails
    
  })

const mapDispatchToProps = dispatch => ({
  onGetContactDetail: id => dispatch(getContactDetail(id)),
  onGetTabCall: id => dispatch(getTabCall(id)),
  onGetTabEmail: id => dispatch(getTabEmail(id)),
  onGetTabNote: id => dispatch(getTabNote(id)),
  addBuyerQuest: (data) => dispatch(addBuyerQuest(data)),
  addTabNote: (data) => dispatch(addTabNote(data)),
  addTabEmail: (data) => dispatch(addTabEmail(data)),
  addTabCall: (data) => dispatch(addTabCall(data)),
  addContactAPP: (data) => dispatch(addContactAPP(data)),
  onGetAppContact: (agentId, id) => dispatch(getAppContatTab(agentId, id)),
  onGetAppContactDetail: id => dispatch(getAppContatDetailTab(id)),
  onGetAllContactAct: id => dispatch(getAllContactActivityTab(id)),
  apiError,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactDetail))

import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { getProjectUUIDAction, getProjectWorksheetLead, getProjectWorksheetLeadUnitDetail, getProjectWorksheetLeadDocDetail} from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";



import {
    Row,
    Col,
    CardBody,
    Card,
  
    Container,
    Nav,
    NavItem,
   
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
  } from "reactstrap"
  import Moment from 'react-moment';
class projectClient extends Component {
    constructor(props) {
        super(props)
        this.state = {
          worksheetLead:''
        }
        this.toggleViewDocument = this.toggleViewDocument.bind(this);   
        this.toggleUnitInformation = this.toggleUnitInformation.bind(this);   
        //this.toggleUploadDocument = this.toggleUploadDocument.bind(this);  
       
      }
      docViewToggle() {
        this.setState({ isDocView: !this.state.isDocView });
      }
      unitViewToggle() {
        this.setState({ isUnitView: !this.state.isUnitView });
      }
      uploadDocToggle() {
        this.setState({ isUploadDoc: !this.state.isUploadDoc });
      }
      componentDidMount() {
        const { match: { params }, ongetProjectUUIDAction, onGetProjectWorksheetLead } = this.props;
        if (params && params.projectId && params.id) {
          ongetProjectUUIDAction(params.projectId);
          onGetProjectWorksheetLead(params.id,3);
        }
      }  
      toggleViewDocument() {
        this.setState({ worksheetLead: '', isDocView: !this.state.isDocView });
        this.docViewToggle();
      } 
      toggleViewDocument = arg => {
        const worksheetLead = arg;
       const { onGetProjectWorksheetLeadDocDetail } = this.props;
        this.setState({
          worksheetLead: {
            id: worksheetLead.id,
          }
        });
       onGetProjectWorksheetLeadDocDetail(worksheetLead.id);
        this.docViewToggle();
    }; 

    toggleUnitInformation() {
      this.setState({ worksheetLead: '', isUnitView: !this.state.isUnitView });
      this.unitViewToggle();
    } 
    toggleUnitInformation = arg => {
      const worksheetLead = arg;
     const { onGetProjectWorksheetLeadUnitDetail } = this.props;
      this.setState({
        worksheetLead: {
          id: worksheetLead.id,
        }
      });
      onGetProjectWorksheetLeadUnitDetail(worksheetLead.id);
      this.unitViewToggle();
  }; 
      render() {
        const { projectData, worksheetLeads,  worksheetUnitData, worksheetDocData  } = this.props;
       
        return (
          <React.Fragment>
              <div className="page-content project-page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Activity")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
         
            <Row>
                   <Col lg="12">
                   <Nav tabs className="projectTab projectNav">
                  
                  <NavItem>
                  <Link className="nav-link " to={"/pre-construction/campaign/current-prospects/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                     
                    >
                      <span className="d-none d-sm-block">{this.props.t("New Leads")}</span>
                      
                    </Link>
                  </NavItem>
                  <NavItem>
                  <Link className="nav-link" to={"/pre-construction/campaign/srr-filled/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                     
                    >
                      <span className="d-none d-sm-block">{this.props.t("Transferred leads")}</span>
                      
                    </Link>
                  </NavItem>
                  <NavItem>
                  <Link className="nav-link" to={"/pre-construction/campaign/worksheet-status/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                     
                    >
                      <span className="d-none d-sm-block">{this.props.t("Worksheet Status")}</span>
                      
                    </Link>
                  </NavItem>
                  <NavItem>
                  <Link className="nav-link active" to={"/pre-construction/campaign/client/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                     
                    >
                      <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                      
                    </Link>
                  </NavItem>
                </Nav>   
                      
                    </Col>  
                             
                </Row> 
                <Row>
                   
                    <Col lg="12">
                    
                        <Card>
                        <CardBody>
                       
                       
                                <div className="table-rep-plugin">
                                                <div
                                                    className=""
                                                    data-pattern="priority-columns"
                                                >
                                                    <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped table-bordered"
                                                    >
                                                    <Thead>
                                                        <Tr>
                                                        <Th>{this.props.t("Name")}</Th>
                                                        <Th data-priority="1">{this.props.t("Date")}</Th>
                                                        <Th data-priority="3">{this.props.t("Email")}</Th>
                                                        <Th data-priority="3">{this.props.t("Phone")}</Th>
                                                        <Th data-priority="3">{this.props.t("Action")}</Th>
                                                    
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                    {map(worksheetLeads, (worksheetLead, prokey) => (
                                                        <Tr>
                                                        <Th>
                                                            
                                                            <span className="co-name">{worksheetLead.name}</span>
                                                        </Th>
                                                        <Td>{worksheetLead.filledDate ? <Moment format="D MMM YY">{worksheetLead.filledDate}</Moment> : '' }</Td>
                                                        <Td>{worksheetLead.email}  </Td>
                                                        <Td> {worksheetLead.cellPhone} </Td>
                                                        <Td> 
                                                        <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem tag={Link} onClick={() => this.toggleViewDocument(worksheetLead)}>View Document</DropdownItem>
                                       
                                        {/* <DropdownItem  href="#" onClick={this.toggleUploadDocument}>Upload Document</DropdownItem> */}
                                        <DropdownItem  href="#"  onClick={() => this.toggleUnitInformation(worksheetLead)}>Unit Informtion</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                                            
                                                            </Td>

                                                        </Tr>
                                                    ))}
                                                        
                                                    </Tbody>
                                                    </Table>
                                                </div>
                                                </div>
                           
                               
                               
                                
                               
                               
                              
                           
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Col>
                </Row>
                </Container>

                <Offcanvas
                            isOpen={this.state.isDocView}
                            direction="end"
                            toggle={this.toggleViewDocument}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleViewDocument}>
                                View Documents
                            </OffcanvasHeader>
                            <OffcanvasBody>
                          
                          <div className="table-responsive">
                          
                      <Table className="table table-sm m-0">
                      
                        <tbody>
                        {map(worksheetDocData, (docData, dockey) => (
                          <tr>
                            <th scope="row">{dockey+1}</th>
                            <td>{docData.documentTitle}</td>
                            <td><a target="_blank" rel="noreferrer" href={docData.documentURL}>View</a></td>
                          </tr>
                          ))} 
                          </tbody>
                          </Table>
                          <p>{" "}</p>
                          
                                                     
            
                    </div>
                          
                     
                              </OffcanvasBody>
                            </Offcanvas>

                            <Offcanvas
                            isOpen={this.state.isUnitView}
                            direction="end"
                            toggle={this.toggleUnitInformation}
                            
                        >
                            <OffcanvasHeader toggle={this.toggleUnitInformation}>
                                View Units
                            </OffcanvasHeader>
                            <OffcanvasBody>
                          
                           
                      <Table className="table table-sm m-0">
                        
                        <tbody>
                        {map(worksheetUnitData, (unitData, unitkey) => (
                          <>
                          <tr>
                            <th scope="row">Choice {unitkey+1}</th>
                            <td>{unitData.optionNumber}/{unitData.optionType}</td>
                            

                          </tr>
                          <tr>
                            <td colSpan="2">{unitData.optionNotes}</td>
                          </tr>
                          </>
                        ))}
                          
                          
                        </tbody>
                      </Table>
                    
                          
                     
                              </OffcanvasBody>
                            </Offcanvas>
            </div>
          </React.Fragment>
        )
      }
}

projectClient.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    className: PropTypes.any,
    worksheetLeads:PropTypes.array,
    onGetProjectWorksheetLead:PropTypes.func,
    onGetProjectWorksheetLeadDocDetail:PropTypes.func,
    onGetProjectWorksheetLeadUnitDetail:PropTypes.func,
    worksheetUnitData:PropTypes.array,
    worksheetDocData:PropTypes.array

  }
  const mapStateToProps = ({ Project }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading,
      worksheetLeads:Project.worksheetLeads,
      worksheetUnitData:Project.worksheetUnitData,
      worksheetDocData:Project.worksheetDocData
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    onGetProjectWorksheetLead:(projectId, statusId) => dispatch(getProjectWorksheetLead(projectId, statusId)),
    onGetProjectWorksheetLeadUnitDetail:(id) => dispatch(getProjectWorksheetLeadUnitDetail(id)),
    onGetProjectWorksheetLeadDocDetail:(id) => dispatch(getProjectWorksheetLeadDocDetail(id)),
  });
export default connect(mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(projectClient)))

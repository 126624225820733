import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import axios from "axios"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {Row,Col, CardBody, Card, CardText, Container,FormGroup, CardTitle, Input, Label,  Offcanvas, OffcanvasHeader, OffcanvasBody,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, } from "reactstrap"
  import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
  import SaveButton from "components/buttons/save";
import RsAdNew from "components/buttons/Adnew";
import RSBarChart from "components/charts/RSBarChart";
import RSPieChart from "components/charts/RSPieChart";
class AgentDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {

      agents:[]
      
    }
    this.viewDetailCanvas = this.viewDetailCanvas.bind(this);
  }
  viewDetailCanvas() {
    this.setState({ isView: !this.state.isView });
  }
  componentDidMount() {
   
  }
  render() {
    const barchartdata = {
      series: [
        {
          name: "Clients",
          data: [3, 8, 16, 0,0,0,0,0,0,0,0,0],
        }
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
      
        grid: {
          borderColor: "#f1f1f1",
        },
        colors: ["#6CE5E8", "#41B8D5"],
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",

          ],
        },
      },
    };
    const leadSourceData = {
      series: [30, 30, 40],
      options: {
        chart: {
          type: 'pie',
        },
        labels: ["Toronto", "Pickering", "Scarborough"],
        colors: ["#6CE5E8", "#41B8D5", "#2D8BBA"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Broker Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row className="project-header mb20">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Dachboard")}
              breadcrumbItem={this.props.t("Agents Performance")}
            />
            </Col>
            <Col xl="5">
                     
            </Col>
            </Row>
            <Row className="mt-20 mb-20">
                <Col sm="1">
                <select name="" id="" className="form-control">
                <option value="">Select Year</option>
                <option value="">2023</option>
                <option value="" selected>2024</option>
                
                
            </select>  
                </Col>
            </Row>
<Row className="mb20">
            <Col lg={6}>
            <RSBarChart title="Monthly Sales" filter={false}  height="370"  series={barchartdata.series} options={barchartdata.options} />
            </Col>
            <Col lg={6}>
            <RSPieChart title="Client Distribution" filter={false} height="420" series={leadSourceData.series} options={leadSourceData.options} />
            </Col>         
            </Row>
              <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                  <div className="card-title mb-4 float-start">Client list</div>
                  <div className="row gy-2 gx-3 float-end mb20">
          
         
          <div className="col-sm-auto">
            
            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search by client name" />
          </div>

          <div className="col-sm-auto">
            
            <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search by project name" />
          </div>
         
       
          

        </div>
                    <div className="clearfix"></div>
                  <table
                      id=""
                      className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Deal value</th>
                            <th>Commission</th>
                            <th>Closing date</th>
                            <th>Deal type</th>
                            <th>City</th>
                            <th>Client type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><Link to="#" onClick={this.viewDetailCanvas}>Rohit grover</Link></td>
                            <td>$ 895,000</td>
                            <td>$ 13,500</td>
                            <td>Oct 24th, 2024</td>
                            <td>Sale</td>
                            <td>Pickering</td>
                            <td>
                            Pre-construction ( Pickering city centre)
                            </td>
                          </tr>
                        </tbody>
                </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          
          </Container>
        </div>


        <Offcanvas
              isOpen={this.state.isView}
              direction="end"
              toggle={this.viewDetailCanvas}
              style={{width:800}}
            >
              <OffcanvasHeader toggle={this.viewDetailCanvas}>
              Deal details
              </OffcanvasHeader>
              <OffcanvasBody>
                <Row className="mb-3">
                        <Col sm={6}>
                            <h5><strong>Agent :</strong> <span className="">Mohit Grover</span></h5>
                        </Col>

                        <Col sm={6}>
                        <h5><strong>Closing date :</strong> <span>Jul 10, 2024</span></h5>
                        </Col>

                </Row>

                <Row className="mb-3">
                        <Col sm={6}>
                            <h5 className="main-heading"><strong>Principal applicant</strong></h5>
                        </Col>

                        <Col sm={6}>
                        <h5 className="main-heading"><strong>Secondary applicant</strong></h5>
                        </Col>

                </Row>

                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Name :</strong> <span className="">Rohit Grover</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Address :</strong> <span className="">1815 Massachusetts Ave, Cambridge, MA 02140, USA</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Mobile :</strong> <span className="">1234567890</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Secondary phone :</strong> <span className="">9876543210</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-3">
                        <Col sm={6}>
                        <h6><strong>Email :</strong> <span className="">rohit@gmail.com</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                            <h5 className="main-heading"><strong>Deal Info</strong></h5>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Type :</strong> <span className="">Buyer</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Property address :</strong> <span className="">1815 Massachusetts Ave, Cambridge, MA 02140, USA</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Sale price :</strong> <span className="">$69,9999</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-3">
                        <Col sm={6}>
                        <h6><strong>Deal date :</strong> <span className="">Aug 10, 2024</span></h6>
                        </Col>

                        <Col sm={6}>
                        <h6><strong>Closing date :</strong> <span className="">Nov 10, 2024</span></h6>
                        </Col>

                </Row>


                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Type :</strong> <span className="">Seller</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Property address :</strong> <span className="">1815 Massachusetts Ave, Cambridge, MA 02140, USA</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={6}>
                        <h6><strong>Sale price :</strong> <span className="">$69,9999</span></h6>
                        </Col>

                        <Col sm={6}>
                        
                        </Col>

                </Row>
                <Row className="mb-3">
                        <Col sm={6}>
                        <h6><strong>Deal date :</strong> <span className="">Aug 10, 2024</span></h6>
                        </Col>

                        <Col sm={6}>
                        <h6><strong>Closing date :</strong> <span className="">Nov 14, 2024</span></h6>
                        </Col>

                </Row>
                <Row className="mb-2">
                        <Col sm={12}>
                            <h5><strong>Note</strong></h5>
                        </Col>

               </Row>
              </OffcanvasBody>
            </Offcanvas>
      </React.Fragment>
    )
  }
}
AgentDetail.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(AgentDetail))

import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import $, { param } from "jquery";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Nav,
  NavItem,
} from "reactstrap";

import { getProjectUUIDAction as onGetProjectUUIDAction } from "../../../store/projects/actions";
import { loadEmailMasterTemplate as onGetTempById } from "../../../store/templates/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import EmailEditor from 'react-email-editor';
import axios from "axios";

const UnEditor = props => {
  const { id, projectId, templateId } = useParams();
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [templateContent, setTemplateContent] = useState('');
  const { masterData, loading } = useSelector(state => ({
    masterData: state.Template.masterData,
    loading: state.Template.loading,
  }));
  //console.log(masterData);  
  useEffect(() => {
      dispatch(onGetTempById(id, templateId));
  }, [dispatch, onGetTempById]);

  const editorRef = useRef(null);
  
 
useEffect(() => {
    const timeoutId = setTimeout(() => {
        if (masterData && _.get(masterData, "projectTemplatePreContent", false) !== false) {
            editorRef.current.editor.loadDesign(JSON.parse(masterData.projectTemplatePreContent.templateContent));
        }
    }, 1000);
   
    return () => clearTimeout(timeoutId);
}, [masterData]);

const exportHtml = () => {
  if (editorRef.current) {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
      const payload = {
        'agentId': parseInt(localStorage.getItem("userId")),
        'emailCampaignFullContent': JSON.stringify(design),
        'emailCampaignProjectId': parseInt(id),
        'emailCampaignTemplateId': parseInt(templateId),
        'emailCampaignTitle' : masterData.templateTitle,
        'emailCampaignContent' : html,
        'IsActive':true
      };
      // console.log('exportHtml', payload);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': str
      }
      toast.loading("Please wait...");
      axios.post('https://rscoreapi.azurewebsites.net/api/ProjectEmailCampaign', payload, {
              headers: headers
            })
            .then((response) => {
              toast.dismiss();
              history.push('/pre-construction/mail-send/' + response.data.projectEmailCampaign.id + '/' + response.data.projectEmailCampaign.emailCampaignProjectId + '/' + response.data.projectEmailCampaign.projectEmailCampaignId);
            })
            .catch((error) => {
             
            }) 
    });
  }
};

const saveasdraft = () => {
  if (editorRef.current) {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
      const payload = {
        'agentId': parseInt(localStorage.getItem("userId")),
        'emailCampaignFullContent': JSON.stringify(design),
        'emailCampaignProjectId': parseInt(id),
        'emailCampaignTemplateId': parseInt(templateId),
        'emailCampaignTitle' : 'Draft',
        'emailCampaignContent' : html,
        'emailCampaignPublishStatus' : 'draft',
        'IsActive':true
      };
      // console.log('exportHtml', payload);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': str
      }
      toast.loading("Please wait...");
      axios.put('https://rscoreapi.azurewebsites.net/api/ProjectEmailCampaign/updateProjectEmailCampaignSaveDraft', payload, {
              headers: headers
            })
            .then((response) => {
              toast.dismiss();
              toast.success("Email Saved as Draft");
              history.push('/pre-construction/my-project');
              
            })
            .catch((error) => {
             
            }) 
    });
  }
};

const sendPreview = () => {
  if (editorRef.current) {
    toast.loading("Please wait...");
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //console.log('exportHtml', html);
      const payload = {
        'agentId': parseInt(localStorage.getItem("userId")),
        'emailCampaignFullContent': JSON.stringify(design),
        'emailCampaignProjectId': parseInt(id),
        'emailCampaignTemplateId': parseInt(templateId),
        'emailCampaignTitle' : 'Preview Email',
        'emailCampaignSubject' : 'Preview Email',
        'emailCampaignContent' : html,
        'emailCampaignCss' : '',
        //'emailCampaignPublishStatus' : 'draft',
        'IsActive':true
      };
      // console.log('exportHtml', payload);
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': str
      }
      //toast.loading("Please wait...");
      axios.post('https://rscoreapi.azurewebsites.net/api/ProjectEmailCampaign/previewprojectemailcampaign', payload, {
              headers: headers
            })
            .then((response) => {
              //toast.dismiss();
              toast.success("Preview Mail Send");
              //history.push('/pre-construction/my-project');
              
            })
            .catch((error) => {
             
            }) 
    });
  }
};

  return (
   
    <div style={{ marginTop: 30 }}>
      {loading ? <div className="loading">Loading&#8230;</div> : null}
      <ToastContainer autoClose={2000} />
      <Container fluid>
      <div className="overlay-email">
      <Row className="mb20">
      <Col sm="6">
      <Link className="btn btn btn-light btn-md ms-2" style={{ marginTop: 34 }} to={`/pre-construction/campaign/send-email/${id}/${projectId}`} ><i className=" bx bx-chevron-left"></i> Back</Link>
      </Col>
          <Col sm="6">
            {/* <Link className="btn btn-primary" to={`/pre-construction/campaign/send-email/${id}/${projectId}`} >Back</Link> */}
            <span className="float-end">
            <button
                type="button"
                className="btn btn-warning w-md me-2"
                style={{ marginTop: 34 }}
                onClick={saveasdraft}
            >
                                 Save as Draft
                                </button>{" "}
                                <button
                                  type="button"
                                  className="btn btn-success w-md me-2"
                                  style={{ marginTop: 34 }}
                                  onClick={sendPreview}
                                >
                                  Send Preview Mail
                                </button>{" "}
            <button onClick={exportHtml}
              
              className="btn btn-primary w-md me-2"
              style={{ marginTop: 34 }}
            >
              Save & Continue
            </button>
            </span>
          </Col>
        </Row>
                        {/* <button className="btn btn-primary">asd</button> */}
                        {/* <div className="overlay-content-email"> */}
     
        <Row className="mb20">
          <Col sm="12">
          <EmailEditor projectId={228097} style={{minHeight:800}} ref={editorRef}     />
          </Col>
        </Row>
        </div>
        {/* </div> */}
      </Container>
    </div>
  );
};

export default withRouter(UnEditor);

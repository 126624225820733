import React from 'react';
import PropTypes from 'prop-types';
import "./rscard.css"

const RsBox = ({ title, description, scoreValue }) => {
  return (
    <div className="rs-card">
      <div className="card-body">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h3 className="mb-0 font-size-18">{title}</h3>
            <h4 className="mb-0">{description}</h4>
          </div>
         
         
        </div>
        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center scorevalue" style={{marginTop:20}}>
          {scoreValue}
          </div>
      </div>
    </div>
  );
};

RsBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  scoreValue: PropTypes.string,
};

RsBox.defaultProps = {
  title: 'Default Title',
  description: 'Default Description',
  scoreValue: '',
};

export default RsBox;
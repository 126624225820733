
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { isEmpty, map } from "lodash";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, ButtonGroup, Button, Table, Offcanvas, OffcanvasHeader, OffcanvasBody, InputGroup } from 'reactstrap';
import SaveButton from 'components/buttons/save';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import RsLink from 'components/buttons/rsLink';
import contactEdit from './contact-edit';
import { getContactDetail } from "../../../store/actions";
class ContactData extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleRightCanvas2 = this.toggleRightCanvas2.bind(this);
        this.toggleRightCanvas3 = this.toggleRightCanvas3.bind(this);
    }
    toggleRightCanvas() {
        this.setState({ isRight: !this.state.isRight });
      }
      toggleRightCanvas2() {
        this.setState({ isRight2: !this.state.isRight2 });
      }
    
      toggleRightCanvas3() {
        this.setState({ isRight3: !this.state.isRight3 });
      }
    
      toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
      }
    componentDidMount() {
        const { match: { params }, onGetContactDetail } = this.props;
        if (params && params.id) {
            onGetContactDetail(params.id);
        }
       
      }


    render() {
        const {contactDetail} = this.props;
        const allLocal = localStorage.getItem("localData");
        const localization = JSON.parse(allLocal);
        return (
                <>
                 {!isEmpty(contactDetail) && (
                  <Card>
                    <CardBody>
                    
                      <h4 className="card-title mb-5"> <span className="float-end">
                        {/* <Link to={"/contact/contact-edit/" + contactDetail.contactId}>Edit</Link> */}
                        <RsLink onClick={this.toggleRightCanvasEdit} className="btn-purpul w-md float-end" iconClass=" bx bx-edit-alt">Edit</RsLink>
                        
                        </span></h4>
                      <div className="table-responsive">
                        <Table className="table mb-0">

                          <tbody>

                            <tr>
                              <th scope="row">Email</th>
                              <td>{contactDetail.email}</td>

                            </tr>
                            <tr>
                              <th scope="row">Mobile</th>
                              <td>{contactDetail.mobile}</td>

                            </tr>
                            <tr>
                              <th scope="row">Home Phone</th>
                              <td>{contactDetail.phone}</td>

                            </tr>
                            <tr>
                              <th scope="row">Office Phone</th>
                              <td>{contactDetail.officePhone}</td>

                            </tr>

                            <tr>
                              <th scope="row">Address</th>
                              <td></td>

                            </tr>
                            <tr>
                              <th scope="row">City</th>
                              <td></td>

                            </tr>
                            <tr>
                              <th scope="row">Postal Code</th>
                              <td></td>

                            </tr>
                            <tr>
                              <th scope="row"> State/Province</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row"> Country</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row"> Last Contact Date</th>
                              <td>  {contactDetail.lastContact}  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Company Name</th>
                              <td>  {contactDetail.organizationName} </td>

                            </tr>
                            <tr>
                              <th scope="row"> Creation Date</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Client</th>
                              <td>  {contactDetail.isClient}  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Vendor</th>
                              <td>{contactDetail.isVendor}  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Job Title</th>
                              <td> {contactDetail.jobTitle}   </td>

                            </tr>
                            <tr>
                              <th scope="row">Status</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row">Last Login</th>
                              <td>    </td>

                            </tr>
                            <tr>
                              <th scope="row">Type</th>
                              <td>  Silver  </td>

                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>

                  </Card>
                )}
               
                </>
        );
    }
}
ContactData.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetContactDetail: PropTypes.object,
  contactDetail: PropTypes.object,
}
const mapStateToProps = ({ contact }) =>
({
    contactDetail: contact.contactDetail,
       
});
  const mapDispatchToProps = dispatch => ({
    onGetContactDetail: id => dispatch(getContactDetail(id)),
  });
  
  export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(withRouter(ContactData)));
